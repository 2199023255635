import React from 'react';
import styled from 'styled-components';

import { Video } from '../../../models/Video';
import { TimeStatus } from './TimeStatus';
import { VideoMetaData } from './VideoMetaData';

export type VideoProps = {
  className?: string;
  video: Video;
  selected?: boolean;
  displayDate?: boolean;
  onSelect?: () => void;
};

export const VideoCard: React.FC<VideoProps> = React.memo(
  ({ className, video, selected = false, displayDate = false, onSelect }) => {
    const videoUrl = video.url;
    const channelUrl = video.channel.url;

    // aタグの入れ子は禁止。objectタグを使うとページ内検索で引っかからないため、各所分割したところを全部リンクにしている。
    return (
      <Wrapper className={className}>
        <ThumbnailContainer href={videoUrl}>
          <ThumbnailWrapper>
            <Thumbnail src={video.thumbnailLarge} alt={video.title} />
          </ThumbnailWrapper>
          <TimeStatusWrapper>
            <TimeStatus video={video} />
          </TimeStatusWrapper>
        </ThumbnailContainer>
        <DetailsContainer>
          <AvatorContainer>
            <AvatorLink href={channelUrl}>
              <Avator src={video.channel.thumbnail}></Avator>
            </AvatorLink>
            <Spacer href={videoUrl} />
          </AvatorContainer>
          <MetaContainer>
            <Title href={videoUrl}>{video.title}</Title>
            <ChannelLink href={channelUrl}>{video.channel.title}</ChannelLink>
            <VideoDetailLink onClick={() => video.hasRelatedVideos() && onSelect && onSelect()}>
              <DetailLinkContent>
                <VideoMetaDataWrapper>
                  <VideoMetaData video={video} displayDate={displayDate} />
                </VideoMetaDataWrapper>
                {video.hasRelatedVideos() && (
                  <RelatedVideosSummary>
                    {video.relatedVideos.concurrent.length > 0 && (
                      <>
                        <DetailIcon src="/images/icon/concurrent.svg" />
                        <DetailCount>{video.relatedVideos.concurrent.length}</DetailCount>
                      </>
                    )}
                    {video.relatedVideos.original.length > 0 && (
                      <>
                        <DetailIcon src="/images/icon/link.svg" />
                        <DetailCount>{video.relatedVideos.original.length}</DetailCount>
                      </>
                    )}
                    {video.relatedVideos.kirinuki.length > 0 && (
                      <>
                        <DetailIcon src="/images/icon/kirinuki.svg" />
                        <DetailCount>{video.relatedVideos.kirinuki.length}</DetailCount>
                      </>
                    )}
                  </RelatedVideosSummary>
                )}
              </DetailLinkContent>
              {video.hasRelatedVideos() && (
                <DetailLinkFooter forceVisible={selected}>{selected ? '▲' : '▼'}</DetailLinkFooter>
              )}
            </VideoDetailLink>
          </MetaContainer>
        </DetailsContainer>
      </Wrapper>
    );
  },
);

const Link = styled.a.attrs(() => ({ target: '_blank' }))``;

const DetailLinkFooter = styled.div<{ forceVisible: boolean }>`
  text-align: center;
  height: 24px;
  font-size: 36px;
  transform: scaleY(0.3);
  user-select: none;
  opacity: ${({ forceVisible }) => (forceVisible ? '1 !important' : 0)};
  @media screen and (max-width: 430px) {
    opacity: 1 !important;
  }
`;

const Wrapper = styled.div`
  :hover {
    ${DetailLinkFooter} {
      opacity: 1;
    }
  }
  display: flex;
  flex-direction: column;
`;

const Spacer = styled(Link)`
  flex-grow: 1;
`;

const Image = styled.img.attrs(() => ({ loading: 'lazy' }))``;

const ThumbnailContainer = styled(Link)`
  display: block;
  position: relative;
  user-select: none;
  flex: 0;
`;
const ThumbnailWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;
`;
const Thumbnail = styled(Image)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const TimeStatusWrapper = styled.div`
  position: absolute;
  right: 4px;
  bottom: 4px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-grow: 1;

  @media screen and (max-width: 430px) {
    padding: 0 12px;
  }
`;

const AvatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
`;

const AvatorLink = styled(Link)`
  flex-shrink: 0;
  padding-top: 12px;
`;
const Avator = styled(Image)`
  width: 36px;
  height: 36px;
  border-radius: 50%;
`;

const MetaContainer = styled.div`
  width: calc(100% - 48px);
  display: flex;
  flex-direction: column;
`;

const Title = styled(Link)`
  display: block;
  font-size: 14px;
  font-weight: bold;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;

  padding-top: 12px;
  padding-left: 12px;
`;

const ChannelLink = styled(Link)`
  display: block;
  font-size: 12px;
  color: #aaaaaa;
  :hover {
    color: #f1f1f1;
  }
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 12px;
`;

const VideoDetailLink = styled.div`
  padding-left: 12px;
  cursor: pointer;
  color: #aaaaaa;
  :hover {
    * {
      color: #f1f1f1;
    }
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

const DetailLinkContent = styled.div`
  display: flex;
`;

const VideoMetaDataWrapper = styled.div`
  display: block;
  flex-grow: 1;
`;

const RelatedVideosSummary = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #aaaaaa;
  flex-grow: 0;
  flex-shrink: 0;

  margin-right: -16px;
  padding-right: 16px;
  padding-left: 4px;
  user-select: none;
`;

const DetailIcon = styled.img`
  width: 12px;
  height: 12px;
`;

const DetailCount = styled.div`
  margin: 0 2px;
`;
