import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

interface AdditionalOptions {
  once?: boolean;
}

type VisibilitySensorProps = React.ComponentProps<typeof VisibilitySensor>;

type Options = AdditionalOptions & VisibilitySensorProps;

export const withVisibilitySensor =
  (options: Options) =>
  <OriginalProps extends {}>(WrappedComponent: React.ComponentType<OriginalProps>) => {
    const { once = false, ...visibilitySensorProps } = options;
    const WithVisibilitySensorHOC: React.FC<OriginalProps> = (props: OriginalProps) => {
      const [visible, setVisible] = useState(false);

      return (
        <VisibilitySensor {...visibilitySensorProps} active={!(once && visible)} onChange={setVisible}>
          {({ isVisible }: { isVisible: boolean }) => <WrappedComponent {...props} active={isVisible} />}
        </VisibilitySensor>
      );
    };
    WithVisibilitySensorHOC.displayName = `WithVisibilitySensorHOC(${WrappedComponent.displayName})`;

    return WithVisibilitySensorHOC;
  };
