import React, { useCallback } from 'react';
import { Menu, MenuItem, Sidebar, SubMenu, useProSidebar } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';

import { Active } from '../atoms/Active';
import { SkinFace } from '../atoms/SkinFace';
import { useMemberSummaryData } from '../hooks/api';

export const MenuButton = () => {
  const { toggleSidebar } = useProSidebar();
  return (
    <>
      <MenuIcon onClick={() => toggleSidebar()} />
    </>
  );
};

type SlideMenuProps = {
  className?: string;
  setLockHeader: (lockHeader: boolean) => void;
};

export const SlideMenu: React.FC<SlideMenuProps> = ({ className, setLockHeader }) => {
  const { toggleSidebar } = useProSidebar();
  const { data: membersData } = useMemberSummaryData(0);
  const members = membersData?.members?.items ?? [];
  const closeSidebar = useCallback(() => {
    toggleSidebar(false);
  }, [toggleSidebar]);

  const scrollToElement = useCallback(
    (element: HTMLElement) => {
      setLockHeader(true);
      setTimeout(() => {
        if (element.getAttribute('id') === 'top') {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
          const targetY = element.getBoundingClientRect().top + window.pageYOffset;
          const offset = 64;
          window.scrollTo({ top: targetY - offset, behavior: 'smooth' });
        }
        setTimeout(() => {
          setLockHeader(false);
        }, 1000);
      }, 100);
    },
    [setLockHeader],
  );

  const hashLinkParams = {
    onClick: closeSidebar,
    scroll: scrollToElement,
  };
  return (
    <Wrapper className={className}>
      <StyledSidebar rtl breakPoint="always" backgroundColor="#0f0f0f">
        <Menu>
          <MenuPlacefolder>
            <b>アツクラLIVE!</b>
            <CloseIcon onClick={() => toggleSidebar(false)} />
          </MenuPlacefolder>
          <StyledSubMenu component={<Link to="/" onClick={closeSidebar} />} label="ホーム" open={true} hidetoggle="1">
            <MenuItem component={<HashLink to="/#top" {...hashLinkParams} />}>トップ</MenuItem>
            <MenuItem component={<HashLink to="/#live" {...hashLinkParams} />}>ライブ配信中の動画</MenuItem>
            <MenuItem component={<HashLink to="/#schedule" {...hashLinkParams} />}>配信予定の動画</MenuItem>
          </StyledSubMenu>
          <MenuItem component={<Link to="/videos?type=archive" onClick={closeSidebar} />}>
            ライブ配信アーカイブ
          </MenuItem>
          <MenuItem component={<Link to="/videos?type=video" onClick={closeSidebar} />}>公開中の動画</MenuItem>
          <MenuItem component={<Link to="/videos?ctype=kirinuki" onClick={closeSidebar} />}>切り抜き動画</MenuItem>
          <MenuItem component={<Link to="/live" onClick={closeSidebar} />}>LIVEモード</MenuItem>
          <StyledSubMenu label="メンバー">
            {members.map((member) => (
              <MenuItem
                key={member.id}
                component={<Link to={`/members/${member.id}`} onClick={closeSidebar} />}
                icon={<SkinFace nameTag={member.id} size={28} />}
              >
                {member.shortName}
                {member.isActive && <StyledActive />}
              </MenuItem>
            ))}
          </StyledSubMenu>
          <StyledSubMenu label="マップ" open={true} hidetoggle="1">
            <MenuItem component={<Link to="/map" onClick={closeSidebar} />}>アツクラ2023マップ</MenuItem>
            <MenuItem component={<Link to="/gakuenmap" onClick={closeSidebar} />}>アツクラ学園マップ</MenuItem>
          </StyledSubMenu>
          <MenuItem component={<Link to="/widget/manual" onClick={closeSidebar} />}>ウィジェット</MenuItem>
          <MenuItem component={<Link to="/about" onClick={closeSidebar} />}>アツクラLIVEについて</MenuItem>
          <MenuItem component={<Link to="/changelog" onClick={closeSidebar} />}>
            更新履歴<UpdateDate>（2024/11/10更新）</UpdateDate>
          </MenuItem>
        </Menu>
      </StyledSidebar>
    </Wrapper>
  );
};

const Icon = styled.img`
  width: 32px;
  height: 32px;
  padding: 8px;
  cursor: pointer;
`;

const MenuIcon = styled(Icon).attrs(() => ({ src: '/images/icon/menu.svg' }))``;
const CloseIcon = styled(Icon).attrs(() => ({ src: '/images/icon/close.svg' }))`
  margin-right: -4px;
`;

const Wrapper = styled.div`
  z-index: 1001;
  user-select: none;
`;

const StyledSidebar = styled(Sidebar)`
  background-color: #0f0f0f;
  border-color: transparent !important;

  direction: ltr;
  * {
    direction: ltr;
  }

  .ps-menu-button {
    &:hover {
      background: #1f1f1f;
    }
  }

  .ps-menu-icon {
    margin-left: 0;
    margin-right: 8px;
  }

  .ps-submenu-content {
    background-color: #191919;

    .ps-menu-button {
      padding-left: 32px;
    }
  }

  .ps-sidebar-backdrop {
    backdrop-filter: blur(4px);
  }
`;

const StyledSubMenu = styled(SubMenu)<{ hidetoggle?: '1' }>`
  .ps-submenu-expand-icon {
    display: ${({ hidetoggle }) => (hidetoggle === '1' ? 'none' : 'inherit')};
  }

  .ps-submenu-expand-icon > span {
    transform: rotate(135deg);

    &[open] {
      transform: rotate(225deg);
    }
  }
`;

const StyledActive = styled(Active)`
  position: absolute;
  left: 12px;
  top: 21px;
`;

const MenuPlacefolder = styled.li`
  display: flex;
  width: 100%;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  height: 50px;
  align-items: center;
  justify-content: space-between;
`;

const UpdateDate = styled.span`
  margin-left: 4px;
  font-size: 14px;
`;
