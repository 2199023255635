import React from 'react';

type DurationProps = {
  value: number;
};

export const Duration: React.FC<DurationProps> = React.memo(({ value }) => {
  const duration = value || 0;
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = duration % 60;

  let status = '';
  if (hours === 0) {
    status = `${minutes}分 ${('00' + seconds).slice(-2)}秒`;
  } else {
    status = `${hours}時間 ${('00' + minutes).slice(-2)}分 ${('00' + seconds).slice(-2)}秒`;
  }
  return <>{status}</>;
});
