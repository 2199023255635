import React from 'react';
import styled from 'styled-components';

import { Video } from '../../../models/Video';

export type TimeStatusProps = {
  video: Video;
};

export const TimeStatus: React.FC<TimeStatusProps> = React.memo(({ video }) => {
  let status: string = '';
  if (video.isLive) {
    status = video.videoType === 'live' ? 'ライブ' : 'プレミア公開';
  } else if (video.isScheduled) {
    status = video.videoType === 'live' ? 'ライブ配信予定' : 'プレミア公開予定';
  } else {
    const duration = video.duration || 0;
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = duration % 60;

    if (hours === 0) {
      status = `${minutes}:${('00' + seconds).slice(-2)}`;
    } else {
      status = `${hours}:${('00' + minutes).slice(-2)}:${('00' + seconds).slice(-2)}`;
    }
  }
  return <Status live={video.isLive ? 'true' : 'false'}>{status}</Status>;
});

const Status = styled.div<{ live: 'true' | 'false' }>`
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 4px;
  background: ${({ live }) => (live === 'true' ? 'rgba(204, 0, 0, 0.9)' : 'rgba(0, 0, 0, 0.8)')};
`;
