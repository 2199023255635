import React from 'react';
import { Helmet } from 'react-helmet-async';

import { useMemberSummaryData } from '../hooks/api';
import { WidgetPageTemplate } from '../templates/WidgetPageTemplate';

export const WidgetPage: React.FC = () => {
  const { data, isLoading } = useMemberSummaryData();
  if (!data || isLoading) {
    return (
      <>
        <Helmet>
          <title>アツクラLIVE! Widget</title>
        </Helmet>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>アツクラLIVE! Widget</title>
      </Helmet>
      <WidgetPageTemplate members={data.members} />
    </>
  );
};
