import React from 'react';
import styled from 'styled-components';

import { Stats } from '../../models/Stats';

type Props = {
  className?: string;
  stats: Stats;
  kirinukiStats: Stats;
};

export const AtsukuraStats: React.FC<Props> = ({ className, stats, kirinukiStats }) => (
  <Wrapper>
    <Item>
      <b>アツクラの現在の動画本数</b>
    </Item>
    <Item>
      <ItemLabel>ライブ配信</ItemLabel>
      <ItemValue>
        <Count>{stats.liveVideoCount}</Count>本
      </ItemValue>
    </Item>
    <Item>
      <ItemLabel>動画</ItemLabel>
      <ItemValue>
        <Count>{stats.videoCount + stats.premiereVideoCount}</Count>本
      </ItemValue>
    </Item>
    <Item>
      <ItemLabel>切り抜き動画</ItemLabel>
      <ItemValue>
        <Count>{kirinukiStats.totalVideoCount}</Count>本
      </ItemValue>
    </Item>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: baseline;
  justify-content: center;

  @media screen and (max-width: 430px) {
    flex-direction: column;
    align-items: center;
    gap: 0px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: baseline;
`;
const ItemLabel = styled.div`
  display: inline-block;
  margin-right: 4px;
  @media screen and (max-width: 430px) {
    width: 100px;
    text-align: right;
  }
`;
const ItemValue = styled.div`
  display: inline-block;
  @media screen and (max-width: 430px) {
    display: block;
    margin-left: auto;
    width: 80px;
    text-align: right;
  }
`;

const Count = styled.span`
  font-size: 1.4em;
  margin-right: 4px;
`;
