import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Video } from '../../models/Video';
import { GroupedVideos, VideoCardList } from './VideoCardList';

type VideoInfoProps = {
  className?: string;
  displayDate?: boolean;
  video: Video | null;
};

export const VideoInfo: React.FC<VideoInfoProps> = ({ className, video, displayDate }) => {
  const groups: GroupedVideos[] = useMemo(() => {
    if (!video) {
      return [];
    }
    const _groups: GroupedVideos[] = [];
    if (video.relatedVideos.kirinuki.length > 0) {
      _groups.push({ label: '切り抜き動画', videos: video.relatedVideos.kirinuki });
    }
    if (video.relatedVideos.original.length > 0) {
      _groups.push({ label: '元の動画', videos: video.relatedVideos.original });
    }
    if (video.relatedVideos.concurrent.length > 0) {
      _groups.push({ label: '同じ時間の他メンバーのライブ配信', videos: video.relatedVideos.concurrent });
    }
    return _groups;
  }, [video]);
  return (
    <Wrapper className={className}>
      <VideoCardList groups={groups} displayDate={displayDate} disableAds={true} />
    </Wrapper>
  );
};

const Wrapper = styled.div``;
