import React from 'react';
import {
  Link,
  /*useLocation*/
} from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';

import { Member } from '../../../models/Member';
import { Arrow } from '../../atoms/Arrow';
import { BlockText } from '../../atoms/BlockText';
import { EmptyPlaceholder } from '../../atoms/EmptyPlacefolder';
import { SkinFace } from '../../atoms/SkinFace';
// import { AdMax } from '../../molecules/ad/AdMax';
// import { AdWrapper } from '../../molecules/ad/AdWrapper';
import { MemberInfo } from '../../organisms/MemberInfo';
import { VideoCardList } from '../../organisms/VideoCardList';
import { AutoLimitVideoCardListLoader } from '../../organisms/VideoCardListLoader';

type MemberPageTemplateProps = {
  className?: string;
  member: Member;
};

export const MemberPageTemplate: React.FC<MemberPageTemplateProps> = ({ className, member }) => {
  // const { pathname } = useLocation();
  return (
    <>
      <Wrapper className={className}>
        <Section>
          <MemberInfoContainer>
            <MemberInfo member={member} />
          </MemberInfoContainer>
        </Section>
        <Separator />
        {member.liveVideos.items.length > 0 && (
          <>
            <Section id="live">
              <VideoCardListWrapper>
                <SectionTitle>
                  <StyledSkinFace nameTag={member.id} size={20} />
                  ライブ配信中の動画
                </SectionTitle>
                <VideoCardList videos={member.liveVideos.items} disableAds={true} />
              </VideoCardListWrapper>
            </Section>
            <Separator />
          </>
        )}
        {member.upcomingVideos.items.length > 0 && (
          <>
            <Section id="schedule">
              <VideoCardListWrapper>
                <SectionTitle>
                  <StyledSkinFace nameTag={member.id} size={20} />
                  配信予定の動画
                </SectionTitle>
                <VideoCardList videos={member.upcomingVideos.items} disableAds={true} />
              </VideoCardListWrapper>
            </Section>
            <Separator />
          </>
        )}
        {/* <AdWrapper>
          <AdMax key={`${pathname}_SP_メンバーページ`} target="mobile" spot="SP_メンバーページ" />
          <AdMax key={`${pathname}_PC_メンバーページ`} target="pc" spot="PC_メンバーページ" />
        </AdWrapper>
        <Separator /> */}

        <Section id="archive">
          <SectionTitle>
            <StyledSkinFace nameTag={member.id} size={20} />
            ライブ配信アーカイブ
          </SectionTitle>
          <VideoCardListWrapper>
            <AutoLimitVideoCardListLoader
              key="published/live"
              memberIdOrAllOrForMembers={member.id}
              videoTypeOrVideoStatus="published/live"
              limitItemsForMobile={2}
              emptyPlaceholder={
                <EmptyPlaceholder>
                  <BlockText>{member.shortName}さんの</BlockText>
                  <BlockText>ライブ配信アーカイブはありません</BlockText>
                </EmptyPlaceholder>
              }
              moreLink={
                <>
                  <MoreLinkMobile to={`/members/${member.id}/videos?type=archive`}>
                    ライブ配信アーカイブ をもっと見る
                  </MoreLinkMobile>
                  <MoreLinkPC id="more-link-published-live" to={`/members/${member.id}/videos?type=archive`}>
                    <Arrow direction="down" weight={2} />
                  </MoreLinkPC>
                  <Tooltip anchorId="more-link-published-live" place="bottom">
                    もっと見る
                  </Tooltip>
                </>
              }
            />
          </VideoCardListWrapper>
        </Section>
        <Separator />
        <Section id="video">
          <SectionTitle>
            <StyledSkinFace nameTag={member.id} size={20} />
            公開中の動画
          </SectionTitle>
          <VideoCardListWrapper>
            <AutoLimitVideoCardListLoader
              key="published/video"
              memberIdOrAllOrForMembers={member.id}
              videoTypeOrVideoStatus="published/video"
              limitItemsForMobile={2}
              emptyPlaceholder={
                <EmptyPlaceholder>
                  <BlockText>{member.shortName}さんの</BlockText>
                  <BlockText>公開中の動画はありません</BlockText>
                </EmptyPlaceholder>
              }
              moreLink={
                <>
                  <MoreLinkMobile to={`/members/${member.id}/videos?type=video`}>
                    公開中の動画 をもっと見る
                  </MoreLinkMobile>
                  <MoreLinkPC id="more-link-published-video" to={`/members/${member.id}/videos?type=video`}>
                    <Arrow direction="down" weight={2} />
                  </MoreLinkPC>
                  <Tooltip anchorId="more-link-published-video" place="bottom">
                    もっと見る
                  </Tooltip>
                </>
              }
            />
          </VideoCardListWrapper>
        </Section>
        <Separator />
        <Section id="video">
          <SectionTitle>
            <StyledSkinFace nameTag={member.id} size={20} />
            切り抜き動画
          </SectionTitle>
          <VideoCardListWrapper>
            <AutoLimitVideoCardListLoader
              key="published/video"
              channelType="kirinuki"
              memberIdOrAllOrForMembers={`members/${member.id}`}
              videoTypeOrVideoStatus="published"
              limitItemsForMobile={2}
              emptyPlaceholder={
                <EmptyPlaceholder>
                  <BlockText>{member.shortName}さんの</BlockText>
                  <BlockText>切り抜き動画はありません</BlockText>
                </EmptyPlaceholder>
              }
              moreLink={
                <>
                  <MoreLinkMobile to={`/members/${member.id}/videos?type=kirinuki`}>
                    切り抜き動画 をもっと見る
                  </MoreLinkMobile>
                  <MoreLinkPC id="more-link-published-video" to={`/members/${member.id}/videos?type=kirinuki`}>
                    <Arrow direction="down" weight={2} />
                  </MoreLinkPC>
                  <Tooltip anchorId="more-link-published-video" place="bottom">
                    もっと見る
                  </Tooltip>
                </>
              }
            />
          </VideoCardListWrapper>
        </Section>
        <Separator />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div``;
const Section = styled.div``;
const SectionTitle = styled.div`
  font-size: 20px;
  display: flex;
  align-items: center;
`;
const MemberInfoContainer = styled.div`
  margin-bottom: 24px;
`;
const VideoCardListWrapper = styled.div``;
const Separator = styled.hr`
  margin-bottom: 24px;
  border-color: #f1f1f130;
  margin-left: auto;
  margin-right: auto;
`;
const StyledSkinFace = styled(SkinFace)`
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
`;

const MoreLinkMobile = styled(Link)`
  display: block;
  padding: 24px;
  margin-bottom: 24px;
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  @media screen and (min-width: 431px) {
    display: none;
  }
`;

const MoreLinkPC = styled(Link)`
  display: flex;
  justify-content: center;
  :hover {
    background: rgba(255, 255, 255, 0.2);
  }
  padding: 6px 0;
  @media screen and (max-width: 430px) {
    display: none;
  }
`;
