export class Group {
  public name: string = '';
  public bgcolor: string = '';
  public fgcolor: string = '';
  public bordercolor: string = '';
  public description: string = '';

  constructor(init?: Partial<Group>) {
    Object.assign(this, init);
  }

  public static fromJSON(data: { [key: string]: any }) {
    return new Group({
      name: data.name,
      bgcolor: data.bgcolor,
      fgcolor: data.fgcolor,
      bordercolor: data.bordercolor,
      description: data.description,
    });
  }
}

export class GroupList {
  public items: Group[] = [];

  constructor(init?: Partial<GroupList>) {
    Object.assign(this, init);
  }

  public static fromItemsJSON(items: { [key: string]: any }[]) {
    return new GroupList({
      items: items.map((item: any) => Group.fromJSON(item)),
    });
  }
}
