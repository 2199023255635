import styled from 'styled-components';

import AutoHide from './AutoHide';

const BaseLayout = styled.div`
  display: grid;
  grid-template-areas:
    'auto-hide-header'
    'header'
    'main'
    'footer'
    'auto-hide-footer';
`;

const AutoHideHeader = styled(AutoHide).attrs(() => ({ position: 'top' }))`
  grid-area: auto-hide-header;

  margin-left: -16px;
  margin-right: -16px;
  margin-top: -16px;
`;

const AutoHideFooter = styled(AutoHide).attrs(() => ({ position: 'bottom' }))`
  grid-area: auto-hide-footer;

  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: -16px;
`;

const Header = styled.div``;
const Main = styled.div``;
const Footer = styled.div``;

type LayoutType = typeof BaseLayout & {
  AutoHideHeader: typeof AutoHideHeader;
  Header: typeof Header;
  Main: typeof Main;
  Footer: typeof Footer;
  AutoHideFooter: typeof AutoHideFooter;
};

const Layout: LayoutType = BaseLayout as any;
Layout.AutoHideHeader = AutoHideHeader;
Layout.Header = Header;
Layout.Main = Main;
Layout.Footer = Footer;
Layout.AutoHideFooter = AutoHideFooter;

export { Layout };
