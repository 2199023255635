import React, { ReactNode, useCallback, useLayoutEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled, { css } from 'styled-components';

import { useElementSize } from '../../hooks';

interface ChildrenRenderArgs {
  OpenContents: React.FC<{ children?: ReactNode }>;
  ClosedContents: React.FC<{ children?: ReactNode }>;
  switchContents: () => void;
  open: () => void;
  close: () => void;
  isOpen: boolean;
}

type OpenTransitionType = 'pull' | 'spread';
export interface SwitchProps {
  className?: string;
  initialOpen?: boolean;
  mode?: OpenTransitionType;
  transitionOptions?: Partial<SwitchTransitionOptions>;
  children: (args: ChildrenRenderArgs) => JSX.Element;
  overflowForOpen?: React.CSSProperties['overflow'];
}

export interface SwitchTransitionOptions {
  duration: number;
  timingFunction: React.CSSProperties['transitionTimingFunction'];
}

export const defaultTransitionOptions: SwitchTransitionOptions = {
  duration: 300,
  timingFunction: 'ease-in-out',
};

export const SwitchContents: React.FC<SwitchProps> = ({
  className,
  children,
  initialOpen = false,
  mode = 'pull',
  transitionOptions = defaultTransitionOptions,
  overflowForOpen = 'hidden',
}) => {
  const { duration, timingFunction } = {
    ...defaultTransitionOptions,
    ...transitionOptions,
  };

  const ref = useRef<HTMLDivElement>(null);

  // 初期状態かどうか(初期状態ではCSSTransitionによるクラス名がつかないため)
  const [isInitialState, setIsInitialState] = useState(true);
  // 開閉状態
  const [isOpen, setIsOpen] = useState(initialOpen);
  // コンテンツの高さ
  const [innerElementHeight, setInnerElementHeight] = useState<number | undefined>(undefined);

  const switchContents = useCallback(() => {
    setIsInitialState(false);
    setIsOpen((state) => !state);
  }, []);
  const open = useCallback(() => {
    setIsInitialState(false);
    setIsOpen(true);
  }, []);
  const close = useCallback(() => {
    setIsInitialState(false);
    setIsOpen(false);
  }, []);

  const { height } = useElementSize(ref);
  useLayoutEffect(() => {
    if (ref.current) {
      setInnerElementHeight(ref.current.clientHeight);
    }
  }, [isOpen, height]);

  const containerClassNames = ['switch-contents__container'];
  if (isInitialState) {
    containerClassNames.push('switch-contents__container--initial');
  }

  return (
    <Wrapper
      className={className}
      style={{ height: innerElementHeight }}
      duration={duration}
      timingFunction={timingFunction}
      isOpen={isOpen}
      overflowForOpen={overflowForOpen}
    >
      <Inner ref={ref} mode={mode} duration={duration} initialOpen={initialOpen} timingFunction={timingFunction}>
        <CSSTransition in={isOpen} timeout={duration}>
          <SwitchContentContainer className={containerClassNames.join(' ')}>
            {children({
              OpenContents,
              ClosedContents,
              switchContents,
              open,
              close,
              isOpen,
            })}
          </SwitchContentContainer>
        </CSSTransition>
      </Inner>
    </Wrapper>
  );
};

const OpenContents: React.FC<{ children?: ReactNode }> = ({ children }) => (
  <div className="switch-contents__contents switch-contents__contents--open">{children}</div>
);

const ClosedContents: React.FC<{ children?: ReactNode }> = ({ children }) => (
  <div className="switch-contents__contents switch-contents__contents--closed">{children}</div>
);

const activeCss = (mode: OpenTransitionType) => css`
  opacity: 1;
`;

const inactiveCss = (mode: OpenTransitionType) => css`
  opacity: 0;
  position: absolute;
  right: 0;
  ${mode === 'pull' ? 'bottom: 0' : 'top: 0'};
  left: 0;
  pointer-events: none;
`;

const Wrapper = styled.div<
  SwitchTransitionOptions & { isOpen: boolean; overflowForOpen: React.CSSProperties['overflow'] }
>`
  overflow: ${({ isOpen, overflowForOpen }) => (isOpen ? overflowForOpen : 'hidden')};
  position: relative;
  transition: ${({ duration, timingFunction }) => `all ${duration}ms ${timingFunction}`};
`;

const Inner = styled.div<SwitchTransitionOptions & { initialOpen: boolean; mode: OpenTransitionType }>`
  position: absolute;
  right: 0;
  ${({ mode }) => (mode === 'pull' ? 'bottom: 0' : 'top: 0')};
  left: 0;

  /* アニメーション関連はここで定義する */
  .switch-contents__container {
    .switch-contents__contents {
      transition: ${({ duration, timingFunction }) => `all ${duration}ms ${timingFunction}`};
    }

    /* 初期状態(CSSTransitionによるクラス名がつかない)の */
    &--initial {
      .switch-contents__contents--open {
        ${({ initialOpen, mode }) => (initialOpen ? activeCss(mode) : inactiveCss(mode))}
      }
      .switch-contents__contents--closed {
        ${({ initialOpen, mode }) => (initialOpen ? inactiveCss(mode) : activeCss(mode))}
      }
    }

    &.enter,
    &.enter-done {
      .switch-contents__contents--open {
        ${({ mode }) => activeCss(mode)}
      }
      .switch-contents__contents--closed {
        ${({ mode }) => inactiveCss(mode)}
      }
    }

    &.exit,
    &.exit-done {
      .switch-contents__contents--open {
        ${({ mode }) => inactiveCss(mode)}
      }
      .switch-contents__contents--closed {
        ${({ mode }) => activeCss(mode)}
      }
    }
  }
`;

const SwitchContentContainer = styled.div`
  position: relative;
`;
