import { useCallback, useEffect, useState } from 'react';

/**
 * ローカルストレージからデータを取得する
 * @param key 取得対象のキー
 * @returns
 */
const getItem = (key: string) => {
  try {
    return window.localStorage.getItem(key);
  } catch (error) {
    // ローカルストレージを利用できない環境など
    console.log(error);
    return null;
  }
};

/**
 * ローカルストレージにデータを保存する
 * @param key 保存対象のキー
 * @param value 保存するデータを文字列形式に変換したもの
 */
const setItem = (key: string, value: string) => {
  try {
    const oldValue = getItem(key);
    window.localStorage.setItem(key, value);
    // 同一タブにstorageイベントが飛ばないので、自前でイベントを発行する
    window.dispatchEvent(new StorageEvent('storage', { key, oldValue, newValue: value }));
  } catch (error) {
    // ローカルストレージを利用できない環境など
    console.log(error);
  }
};

/**
 * ローカルストレージの情報を利用するためのカスタムHooks
 *
 * @param key 扱う対象の情報を格納するキー
 * @param initialValue 値が存在しない場合の初期値
 * @param changeDetection 値の変更を検知するか（デフォルト: true）
 * @returns
 */
export const useLocalStorage = <T>(key: string, initialValue: T, changeDetection = true): [T, (value: T) => void] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    const item = getItem(key);
    return item ? JSON.parse(item) : initialValue;
  });

  const setValue = useCallback(
    (value: T) => {
      try {
        setStoredValue(value);
        setItem(key, JSON.stringify(value));
      } catch (error) {
        // ローカルストレージを利用できない環境など
        console.log(error);
      }
    },
    [key],
  );

  // 変更検知処理
  useEffect(() => {
    if (changeDetection) {
      const handler = (event: StorageEvent) => {
        // キーが監視対象、かつ新旧値が異なる場合、新しい値を利用する
        if (event.key === key && event.oldValue !== event.newValue) {
          const item = event.newValue;
          setStoredValue(item ? JSON.parse(item) : initialValue);
        }
      };

      // StorageEventをリスナー登録
      // 変更検知は、LocalStorageのデータ全体が対象で、特定キーの指定はできない
      window.addEventListener('storage', handler);
      return () => {
        window.removeEventListener('storage', handler);
      };
    }
  }, [changeDetection, initialValue, key, setValue]);

  return [storedValue, setValue];
};
