export class VideoStats {
  public viewCount: number = 0;
  public likeCount: number = 0;
  public favoriteCount: number = 0;
  public commentCount: number = 0;

  constructor(init?: Partial<VideoStats>) {
    Object.assign(this, init);
  }

  public static fromJSON(data: { [key: string]: any }) {
    return new VideoStats({
      viewCount: data.viewCount,
      likeCount: data.likeCount,
      favoriteCount: data.favoriteCount,
      commentCount: data.commentCount,
    });
  }
}
