import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';

type FromNowProps = {
  time: Dayjs | null;
  displayBeforeTime?: boolean;
  displayAfterTime?: boolean;
};

export const FromNow: React.FC<FromNowProps> = React.memo(({ time, displayBeforeTime, displayAfterTime }) => {
  if (time === null) {
    return <></>;
  }
  const currentTime = dayjs();
  if (time.isBefore(currentTime)) {
    return (
      <>
        {time.fromNow()}
        {displayBeforeTime && (
          <>
            （<SimpleTime time={time} baseTime={currentTime} />）
          </>
        )}
      </>
    );
  } else {
    return (
      <>
        {time.fromNow()}
        {displayAfterTime && (
          <>
            （<SimpleTime time={time} baseTime={currentTime} />）
          </>
        )}
      </>
    );
  }
});

type FromNowWithIntervalProps = {
  time: Dayjs | null;
  interval?: number;
  beforeLabel?: string;
  afterLabel?: string;
  displayBeforeTime?: boolean;
  displayAfterTime?: boolean;
};

export const FromNowWithInterval: React.FC<FromNowWithIntervalProps> = React.memo(
  ({ time, interval, beforeLabel, afterLabel, displayBeforeTime, displayAfterTime }) => {
    const [currentTime, setCurrentTime] = useState(dayjs());
    useEffect(() => {
      const timerId = setInterval(() => {
        setCurrentTime(dayjs());
      }, interval);

      return () => {
        clearInterval(timerId);
      };
    }, [interval]);
    if (time === null) {
      return <></>;
    }

    if (beforeLabel && time.isBefore(currentTime)) {
      return (
        <>
          {beforeLabel}
          {displayBeforeTime && (
            <>
              （<SimpleTime time={time} baseTime={currentTime} />）
            </>
          )}
        </>
      );
    } else if (afterLabel && time.isAfter(currentTime)) {
      return (
        <>
          {afterLabel}
          {displayAfterTime && (
            <>
              （<SimpleTime time={time} baseTime={currentTime} />）
            </>
          )}
        </>
      );
    }

    return <>{time.from(currentTime)}</>;
  },
);

type SimpleTimeProps = {
  time: Dayjs;
  baseTime: Dayjs;
};

export const SimpleTime: React.FC<SimpleTimeProps> = ({ time, baseTime }) => {
  let format: string;
  if (time.format('YYYY/MM/DD') === baseTime.format('YYYY/MM/DD')) {
    format = 'HH:mm';
  } else if (time.year === baseTime.year) {
    format = 'MM/DD HH:mm';
  } else {
    format = 'YYYY/MM/DD HH:mm';
  }

  return <>{time.format(format)}</>;
};
