import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

const SLIDE_WIDTH_WINDOW = 1;
interface Props {
  className?: string;
  children?: React.ReactNode;
  slideSpeed?: number;
  animationIterationCount?: React.CSSProperties['animationIterationCount'];
}

export const Marquee: React.FC<Props> = ({ className, children, slideSpeed, animationIterationCount = 'infinite' }) => {
  const wrapper = useRef<HTMLDivElement>(null);
  const ticker = useRef<HTMLDivElement>(null);

  const [slideWidth, setSlideWidth] = useState<number>(0);
  useEffect(() => {
    const widthDiff = ticker.current!.offsetWidth - wrapper.current!.offsetWidth;
    if (widthDiff > 0) {
      setSlideWidth(Math.ceil(widthDiff / SLIDE_WIDTH_WINDOW) * SLIDE_WIDTH_WINDOW);
    }
  }, []);

  return (
    <Wrapper className={className} ref={wrapper}>
      <Ticker
        ref={ticker}
        slideWidth={slideWidth}
        slideSpeed={slideSpeed}
        animationIterationCount={animationIterationCount}
      >
        {children}
      </Ticker>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  /* background-color: rgba(32, 39, 48, 0.8); */
  text-align: center;
  overflow: hidden;
  line-height: 1;
`;

const Ticker = styled.div<{
  slideWidth: number;
  slideSpeed?: number;
  animationIterationCount: React.CSSProperties['animationIterationCount'];
}>`
  position: relative;
  display: inline-block;
  vertical-align: middle;

  ${({ slideWidth, slideSpeed = 5, animationIterationCount }) => {
    if (!slideWidth) {
      return null;
    }

    const interval = 3000;
    const rawDurationMs = (slideWidth * 1000) / slideSpeed;
    const durationMs = rawDurationMs + interval;
    return css`
      @keyframes marquee${slideWidth} {
        0% {
          transform: translate3d(0, 0, 0);
        }
        ${(interval / 2 / durationMs) * 100}% {
          transform: translate3d(0, 0, 0);
        }
        ${100 - (interval / 2 / durationMs) * 100}% {
          transform: translate3d(-${slideWidth}px, 0, 0);
        }
        100% {
          transform: translate3d(-${slideWidth}px, 0, 0);
        }
      }

      animation-duration: ${durationMs}ms;
      animation-name: ${`marquee${slideWidth}`};
      animation-iteration-count: ${animationIterationCount};
      animation-timing-function: linear;
    `;
  }}
`;
