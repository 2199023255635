import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import { Member, MonthlyStat } from '../../../models/Member';
import { Active } from '../../atoms/Active';
import { Arrow } from '../../atoms/Arrow';
import { Count } from '../../atoms/Count';
import { Duration } from '../../atoms/Duration';
import { FromNow } from '../../atoms/FromNow';
import { GroupLabel } from '../../atoms/GroupLabel';
import { Skin } from '../../atoms/Skin/Skin';
import { useLocalStorage } from '../../hooks/storage';
import { OnlineHistogram } from '../../molecules/OnlineHistogram';
import { SwitchContents } from '../../molecules/SwitchContents';
import { VideoChart } from '../../molecules/VideoChart';

export type MemberInfoProps = {
  className?: string;
  member: Member;
};

const TOTAL_LABEL = '2023年';
export const MemberInfo: React.FC<MemberInfoProps> = React.memo(({ className, member }) => {
  const [isStatsEnabled] = useLocalStorage('IS_STATS_ENABLED', false, true);
  const [isStatsOpened, setIsStatsOpened] = useLocalStorage('IS_STATS_OPENED', false, false);
  const [statsTarget, setStatsTarget] = useState<string>(TOTAL_LABEL);

  const totalStats = useMemo(
    () =>
      new MonthlyStat({
        label: TOTAL_LABEL,
        videoTypes: member.videoTypes,
        stats: member.stats,
        onlineHistogram: member.onlineHistogram,
      }),
    [member.onlineHistogram, member.stats, member.videoTypes],
  );
  const statsDatas = useMemo(() => {
    return [totalStats, ...member.monthlyStats];
  }, [member.monthlyStats, totalStats]);

  const selectedStats: MonthlyStat = useMemo(
    () => statsDatas.find((data) => data.label === statsTarget) ?? totalStats,
    [statsDatas, statsTarget, totalStats],
  );

  return (
    <Wrapper className={className}>
      <ImageContainer>
        <Skin nameTag={member.id} size={360} />
      </ImageContainer>
      <InfoContainer>
        <Name>{member.shortName}</Name>
        <Status>
          {member.status === 'online' ? (
            <Link href={member.liveVideoUrl} target="_blank">
              <StyledActive />
              ライブ配信中
            </Link>
          ) : (
            <>
              {member.lastOnlineTime && (
                <>
                  <FromNow time={member.lastOnlineTime} />
                  に配信済み
                </>
              )}
            </>
          )}
        </Status>
        <GroupLabelContainer>
          {member.groups.items.map((group) => (
            <GroupLabel key={group.name} group={group} />
          ))}
        </GroupLabelContainer>
        <Description>{member.description}</Description>
        <SelectContainer>
          <Select value={statsTarget} onChange={(event) => setStatsTarget(event.target.value)}>
            {statsDatas.map((data) => (
              <option key={data.label} value={data.label}>
                {data.label}
              </option>
            ))}
          </Select>
        </SelectContainer>
        <OnlineHistogramContainer>
          <VideoStatsItem>
            <VideoStatsItemLabel style={{ width: '100%' }}>アツクラのライブ配信を行なっている時間</VideoStatsItemLabel>
          </VideoStatsItem>
          <OnlineHistogramWrapper>
            <OnlineHistogram values={selectedStats!.onlineHistogram.items} />
          </OnlineHistogramWrapper>
        </OnlineHistogramContainer>
        <SwitchContents
          mode="spread"
          initialOpen={isStatsOpened}
          overflowForOpen="visible"
          transitionOptions={{ duration: 0 }}
        >
          {({ OpenContents, ClosedContents, open, close }) => {
            return (
              <>
                <OpenContents>
                  <VideoStats>
                    <VideoStatsInfoContainer>
                      <VideoStatsItem>
                        <VideoStatsItemLabel>動画本数</VideoStatsItemLabel>
                        <VideoStatsItemValue>{selectedStats.stats.totalVideoCount}本</VideoStatsItemValue>
                      </VideoStatsItem>
                      <VideoStatsItem>
                        <VideoStatsItemLabel>総動画時間</VideoStatsItemLabel>
                        <VideoStatsItemValue>
                          <Duration value={selectedStats.stats.totalDuration} />
                        </VideoStatsItemValue>
                      </VideoStatsItem>
                      <VideoStatsItem>
                        <VideoStatsItemLabel style={{ paddingLeft: 12 }}>ライブ配信時間</VideoStatsItemLabel>
                        <VideoStatsItemValue>
                          <Duration value={selectedStats.stats.liveVideoDuration} />
                        </VideoStatsItemValue>
                      </VideoStatsItem>
                      <VideoStatsItem>
                        <VideoStatsItemLabel style={{ paddingLeft: 12 }}>動画時間</VideoStatsItemLabel>
                        <VideoStatsItemValue>
                          <Duration
                            value={selectedStats.stats.videoDuration + selectedStats.stats.premiereVideoDuration}
                          />
                        </VideoStatsItemValue>
                      </VideoStatsItem>
                      {isStatsEnabled && (
                        <>
                          <VideoStatsItem>
                            <VideoStatsItemLabel>総再生回数</VideoStatsItemLabel>
                            <VideoStatsItemValue>
                              <Count value={selectedStats.stats.viewCount} />回
                            </VideoStatsItemValue>
                          </VideoStatsItem>
                          <VideoStatsItem>
                            <VideoStatsItemLabel>総高評価数</VideoStatsItemLabel>
                            <VideoStatsItemValue>
                              <Count value={selectedStats.stats.likeCount} />
                            </VideoStatsItemValue>
                          </VideoStatsItem>
                        </>
                      )}
                    </VideoStatsInfoContainer>
                    <VideoChartContainer>
                      <VideoStatsItem>
                        <VideoStatsItemLabel>動画内訳</VideoStatsItemLabel>
                      </VideoStatsItem>
                      <VideoChart videoTypes={selectedStats.videoTypes} size="small" />
                    </VideoChartContainer>
                  </VideoStats>
                  <SwitchLabel
                    onClick={() => {
                      setIsStatsOpened(false);
                      close();
                    }}
                  >
                    詳細を閉じる
                    <StyledArrow direction="up" />
                  </SwitchLabel>
                </OpenContents>
                <ClosedContents>
                  <VideoStats>
                    <VideoStatsInfoContainer>
                      <VideoStatsItem>
                        <VideoStatsItemLabel>動画本数</VideoStatsItemLabel>
                        <VideoStatsItemValue>{selectedStats.stats.totalVideoCount}本</VideoStatsItemValue>
                      </VideoStatsItem>
                      <VideoStatsItem>
                        <VideoStatsItemLabel>総動画時間</VideoStatsItemLabel>
                        <VideoStatsItemValue>
                          <Duration value={selectedStats.stats.totalDuration} />
                        </VideoStatsItemValue>
                      </VideoStatsItem>
                    </VideoStatsInfoContainer>
                  </VideoStats>
                  <SwitchLabel
                    onClick={() => {
                      setIsStatsOpened(true);
                      open();
                    }}
                  >
                    詳細を見る
                    <StyledArrow direction="down" />
                  </SwitchLabel>
                </ClosedContents>
              </>
            );
          }}
        </SwitchContents>
        <SocialLinkContainer>
          {member.channels.map((channel) => (
            <SocialLinkWrapper key={channel.customUrl}>
              <SocialLink href={channel.url}>
                <YouTubeLinkIcon />
                <SocialLinkLabel>YouTube {channel.title}</SocialLinkLabel>
              </SocialLink>
            </SocialLinkWrapper>
          ))}
          <SocialLinkWrapper>
            <SocialLink href={member.twitterUrl}>
              <TwitterLinkIcon />
              <SocialLinkLabel>Twitter @{member.twitterUsername}</SocialLinkLabel>
            </SocialLink>
          </SocialLinkWrapper>
        </SocialLinkContainer>
      </InfoContainer>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  display: flex;

  @media screen and (max-width: 430px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ImageContainer = styled.div`
  flex-shrink: 0;
`;
const Name = styled.div`
  font-size: 36px;
  font-weight: bold;
  line-height: 2;
`;

const Status = styled.div`
  font-size: 12px;
  color: #aaaaaa;
  margin: -12px 0 24px 0;
`;

const Description = styled.div`
  margin: 12px 0;
`;

const InfoContainer = styled.div`
  flex-grow: 1;
  @media screen and (max-width: 430px) {
    width: 100%;
  }
`;
const GroupLabelContainer = styled.div`
  margin-bottom: 36px;
`;

const VideoStatsInfoContainer = styled.div`
  margin-right: 64px;
  width: 300px;
  @media screen and (max-width: 430px) {
    margin-right: 0;
    margin-bottom: 12px;
  }
`;
const VideoChartContainer = styled.div``;

const VideoStats = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  @media screen and (max-width: 430px) {
    flex-direction: column;
    align-items: center;
  }
`;
const VideoStatsItem = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 430px) {
    width: 100%;
  }
`;
const VideoStatsItemLabel = styled.div`
  font-weight: bold;
  width: 150px;
`;
const VideoStatsItemValue = styled.div`
  text-align: right;
  @media screen and (max-width: 430px) {
    margin-left: auto;
  }
`;

const OnlineHistogramContainer = styled.div``;

const OnlineHistogramWrapper = styled.div`
  min-height: 120px;
  height: 120px;
  max-width: 640px;
`;

const SwitchLabel = styled.div`
  display: inline-block;
  color: #999;
  font-size: 16px;
  height: 40px;
  cursor: pointer;
  padding: 12px 0;
`;

const StyledArrow = styled(Arrow).attrs(() => ({
  color: '#999',
  length: 14,
  weight: 2,
}))`
  margin-left: 8px;
  margin-right: 8px;
`;

const Link = styled.a.attrs(() => ({ target: '_blank' }))``;

const SocialLinkContainer = styled.div`
  margin-top: 24px;
`;
const SocialLinkWrapper = styled.div``;
const SocialLink = styled(Link)`
  display: inline-block;
`;
const SocialLinkIcon = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 4px;
  vertical-align: middle;
`;
const YouTubeLinkIcon = styled(SocialLinkIcon)`
  background: #db0001;
`;
const TwitterLinkIcon = styled(SocialLinkIcon)`
  background: #5a99ed;
`;
const SocialLinkLabel = styled.span`
  display: inline-block;
  height: 24px;
  line-height: 1.6;
  vertical-align: middle;
  margin-left: 4px;
`;

const StyledActive = styled(Active)`
  margin: 0 4px;
`;

const SelectContainer = styled.div`
  display: flex;
  /* justify-content: flex-end; */
`;

const Select = styled.select`
  color: #1f1f1f;
  font-size: 14px;
  outline: none;
`;
