import React from 'react';
import styled from 'styled-components';

import { Group } from '../../../models/Group';

type GroupProps = {
  className?: string;
  group: Group;
};

export const GroupLabel: React.FC<GroupProps> = React.memo(({ className, group }) => {
  return (
    <Label
      className={className}
      style={{
        color: group.fgcolor,
        background: group.bgcolor,
        borderColor: group.bordercolor,
        borderStyle: 'solid',
      }}
    >
      {group.name}
    </Label>
  );
});

const Label = styled.div`
  display: inline-block;
  font-weight: bold;
  border-radius: 4px;
  padding: 2px 8px;
  margin-bottom: 4px;
  :not(:last-child) {
    margin-right: 8px;
  }
`;
