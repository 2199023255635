import React, { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import styled from 'styled-components';

import { SwitchImage } from '../atoms/SwitchImage';
import { usePrefetchImages } from '../hooks';

const ATSUKURA_MAPS = {
  atsukura_gakuen_before: 'アツクラ学園 開始時',
  atsukura_gakuen_after: 'アツクラ学園 マップ配布時',
} as const;

type AtsukuraMapKey = keyof typeof ATSUKURA_MAPS;
const MAP_IMAGE_URLS = Object.keys(ATSUKURA_MAPS);

export const AtsukuraGakuenMapPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const hash = location.hash.replace('#', '');
  const defaultMap = useMemo(() => {
    return MAP_IMAGE_URLS[MAP_IMAGE_URLS.length - 1] as AtsukuraMapKey;
  }, []);
  const filteredHash = Object.keys(ATSUKURA_MAPS).includes(hash) ? (hash as AtsukuraMapKey) : defaultMap;
  const [selectedMapKey, setSelectedMapKey] = useState<AtsukuraMapKey>(filteredHash);
  const setMapKey = useCallback(
    (mapKey: AtsukuraMapKey) => {
      setSelectedMapKey(mapKey);
      navigate(`#${mapKey}`);
    },
    [navigate],
  );
  usePrefetchImages(MAP_IMAGE_URLS);

  return (
    <>
      <Helmet>
        <title>アツクラ学園マップ | アツクラLIVE!</title>
        <meta property="og:image" content={`https://atsukura.live/images/gakuenmap/${selectedMapKey}.png`} />
      </Helmet>
      <Wrapper>
        <Title>アツクラ学園マップ</Title>
        <Description>
          アツクラ学園のマップは配布マップダウンロードはこちらから
          <br />
          <a
            href="https://minecraft-mcworld.com/106315/"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'underline' }}
          >
            アツクラ学園 | クラフターズコロニー　-マイクラの配布サイト-
          </a>
        </Description>

        <TwitterContainer>
          <TwitterTweetEmbed tweetId={'1855406823186296932'} />
        </TwitterContainer>
        <StyledSwitchImage src={`/images/gakuenmap/${selectedMapKey}.png`} transitionDelay={180} />
        <MonthContainer>
          {Object.entries(ATSUKURA_MAPS).map(([mapKey, label]) => (
            <Month
              key={mapKey}
              onClick={() => setMapKey(mapKey as AtsukuraMapKey)}
              selected={mapKey === selectedMapKey}
            >
              {label}
            </Month>
          ))}
        </MonthContainer>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  padding-bottom: 96px;
`;

const Title = styled.h1`
  margin-top: 48px;
  margin-bottom: 12px;
`;

const StyledSwitchImage = styled(SwitchImage)`
  width: 100%;
  max-width: 1140px;

  @media screen and (max-width: 430px) {
    margin-left: -16px;
    margin-right: -16px;
    width: calc(100% + 32px);
  }
`;

const MonthContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Month = styled.a<{ selected: boolean }>`
  width: 240px;
  height: 48px;
  display: grid;
  place-items: center;
  color: ${({ selected }) => (selected ? '#f1f1f1' : '#999')};
  cursor: pointer;
  user-select: none;
`;

const Description = styled.div`
  padding: 24px 0;
`;

const TwitterContainer = styled.div`
  width: 100%;
  margin-bottom: 48px;
`;
