import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Balloon } from '../atoms/Balloon';

type SupportRequestProps = {
  className?: string;
  active?: boolean;
};

const messages = [
  'アツクラLIVE! を気に入ったら、応援してもらえると嬉しいです！',
  <>
    Twitter アツクラLIVE!bot{' '}
    <a href="https://twitter.com/atsukurabot" target="_blank" style={{ color: '#5a99ed' }} rel="noreferrer">
      @atsukurabot
    </a>{' '}
    もフォローお願いします！
  </>,
  'こんな機能あったらいいな！をTwitterで教えてください！',
];

export const SupportRequest: React.FC<SupportRequestProps> = ({ className, active = true }) => {
  const [messageIndex, setMessageIndex] = useState(0);
  const switchMessage = useCallback(() => setMessageIndex((index) => (index + 1) % messages.length), []);
  useEffect(() => {
    if (active) {
      const timerId = window.setInterval(switchMessage, 10000);
      return () => {
        window.clearInterval(timerId);
      };
    }
  }, [switchMessage, active]);

  return (
    <Wrapper className={className}>
      <SupportWrapper>
        <BalloonWrapper onDoubleClick={switchMessage}>
          <ChickenIconWrapper>
            <ChickenIcon active={active} />
            <ChickenIconLabel>親ニワトリ</ChickenIconLabel>
          </ChickenIconWrapper>
          <Balloon>
            <BalloonLabel>{messages[messageIndex]}</BalloonLabel>
          </Balloon>
        </BalloonWrapper>
        <SupportButton href="https://ofuse.me/mcrtahuman/letter" target="_blank">
          アツクラLIVE!を応援する
        </SupportButton>
      </SupportWrapper>
      <SupportDesctiption>
        面倒な登録なく利用できます。いただいた応援は、インフラ費などのサービス運用、サービス開発、コーヒー代（親ニワトリのエネルギー源）などに利用させていただきます。
      </SupportDesctiption>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SupportWrapper = styled.div`
  @media screen and (min-width: 431px) {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
  }
`;

const SupportButton = styled.a`
  display: inline-block;
  font-weight: bold;
  color: white;
  background: #29cece;
  line-height: 1;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  @media screen and (max-width: 430px) {
    width: 100%;
  }
  flex-shrink: 0;
`;

const SupportDesctiption = styled.div`
  padding: 8px 4px;
  font-size: 12px;
  color: #999;
`;

const BalloonWrapper = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  @media screen and (min-width: 930px) {
    width: 670px;
  }
  min-height: 138px;
`;
const ChickenIconWrapper = styled.div`
  min-width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ChickenIcon = styled.img.attrs(() => ({ src: '/images/image/chicken.png' }))<{ active: boolean }>`
  width: 60px;
  height: 60px;

  @keyframes jump {
    0% {
      transform: translate(0%, 0%);
    }
    92% {
      transform: translate(0%, 0%);
    }
    94% {
      transform: translate(0%, -10%);
    }
    96% {
      transform: translate(0%, 0%);
    }
    98% {
      transform: translate(0%, -10%);
    }
    100% {
      transform: translate(0%, -10%);
    }
  }

  ${({ active }) => (active ? 'animation: jump 5s linear infinite;' : '')}
`;
const ChickenIconLabel = styled.div`
  text-align: center;
  font-size: 12px;
  font-weight: bold;
`;

const BalloonLabel = styled.p`
  font-weight: bold;
`;
