import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Channel, ChannelList } from '../../models/Channel';

type KirinukiChannelsTemplateProps = {
  className?: string;
  channels: ChannelList;
};

export const KirinukiChannelsTemplate: React.FC<KirinukiChannelsTemplateProps> = ({ className, channels }) => {
  const sortedChannels = channels.items
    .filter((channel) => channel.stats.totalVideoCount > 0)
    .sort((a: Channel, b: Channel) => {
      if (a.stats.totalVideoCount === b.stats.totalVideoCount) {
        return 0;
      } else if (a.stats.totalVideoCount < b.stats.totalVideoCount) {
        return 1;
      }
      return -1;
    });

  return (
    <Wrapper>
      <Title>切り抜きチャンネル</Title>
      <Description>
        <p>アツクラLIVE!の切り抜き動画は以下のチャンネルの情報を取り上げさせていただいています。</p>
        <p>他にもアツクラ動画を扱っている切り抜きチャンネルがあるよといった情報はお知らせください。</p>
        <p>
          連絡先は <StyledLink to="/about">アツクラLIVEについて</StyledLink> を参照してください。
        </p>
      </Description>
      {sortedChannels.map((channel) => (
        <SocialLinkWrapper key={channel.customUrl}>
          <SocialLink href={channel.url}>
            <SocialLinkIcon src="/images/icon/youtube.svg" />
            <SocialLinkLabel>
              {channel.title} （{channel.stats.totalVideoCount}）
            </SocialLinkLabel>
          </SocialLink>
        </SocialLinkWrapper>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-bottom: 96px;
`;

const Title = styled.h1``;
const Description = styled.div`
  margin: 36px 0;
  p {
    margin: 12px 0;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

const SocialLinkWrapper = styled.div`
  margin: 12px 0;
`;
const SocialLink = styled.a.attrs(() => ({ target: '_blank' }))`
  display: inline;
`;
const SocialLinkIcon = styled.img`
  display: inline;
  width: 24px;
  vertical-align: middle;
`;
const SocialLinkLabel = styled.span`
  display: inline;
  height: 24px;
  line-height: 1.6;
  vertical-align: middle;
  margin-left: 4px;
`;
