import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  fixed?: boolean;
  position: 'top' | 'bottom';
  onMove?: (scrollDistance: number, contentHeight: number) => void;
  children: React.ReactNode;
}

let prevScrollTop: number = 0;

const AutoHide: React.FC<Props> = ({ className, fixed = false, position, children, onMove }) => {
  const [scrollDistance, setScrollDistance] = useState(0);
  const [wrapperHeight, setWrapperHeight] = useState<number | undefined>(undefined);
  const autoHideElement = useRef<HTMLDivElement>(null);
  const onScroll = useCallback(() => {
    const scrollTop = document.scrollingElement!.scrollTop;
    const scrollDiff = scrollTop - prevScrollTop;
    prevScrollTop = scrollTop;

    if (fixed || !autoHideElement || !autoHideElement.current) {
      if (scrollDistance !== 0) {
        setScrollDistance(0);
      }
      return;
    }

    let nextScrollDistance: number;
    const contentHeight = autoHideElement.current.clientHeight;
    if (position === 'top') {
      nextScrollDistance = Math.min(0, Math.max(Math.max(-contentHeight, -scrollTop), scrollDistance - scrollDiff));
    } else {
      const scrollBottom = document.scrollingElement!.scrollHeight - window.innerHeight - scrollTop;
      nextScrollDistance = Math.min(0, Math.max(Math.max(-contentHeight, -scrollBottom), scrollDistance + scrollDiff));
    }

    setScrollDistance(nextScrollDistance);
    if (scrollDistance !== nextScrollDistance && onMove) {
      onMove(nextScrollDistance, contentHeight);
    }
  }, [fixed, onMove, position, scrollDistance]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    setWrapperHeight(autoHideElement.current!.clientHeight);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  const autoHideElementStyle =
    position === 'top'
      ? { top: 0, transform: `translate3d(0, ${scrollDistance}px, 0)` }
      : { bottom: 0, transform: `translate3d(0, ${-scrollDistance}px, 0)` };

  const wrapperElementStyle = {
    height: wrapperHeight,
  };

  return (
    <Wrapper className={className} style={wrapperElementStyle}>
      <Floating ref={autoHideElement} style={autoHideElementStyle}>
        <FloatingBackgGround position={position} />
        <FloatingInner>{children}</FloatingInner>
      </Floating>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const Floating = styled.div`
  z-index: 1000;
  position: fixed;
  width: 100%;
`;

const FloatingInner = styled.div`
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  border-bottom: 2px solid #0f0f0f;
  padding: 8px 16px;
`;

const FloatingBackgGround = styled.div<{ position: 'top' | 'bottom' }>`
  width: 100%;
  height: 100%;

  position: absolute;
  top: ${({ position }) => (position === 'top' ? '-1px' : '0')};
  left: 0;
  right: 0;
  top: ${({ position }) => (position === 'bottom' ? '-1px' : '0')};
  background: #0f0f0f;
  filter: opacity(90%);
`;

export default AutoHide;
