import { useEffect, useState } from 'react';

type DisplayType = 'mobile' | 'pc';
const getDisplayType = () => {
  return window.matchMedia('screen and (max-width: 430px)').matches ? 'mobile' : 'pc';
};

export const useDisplayType = () => {
  const [displayType, setDisplayType] = useState<DisplayType>(getDisplayType());

  useEffect(() => {
    const handleResize = () => setDisplayType(getDisplayType());
    setDisplayType(getDisplayType());
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return displayType;
};

export const useIsMobile = () => {
  return useDisplayType() === 'mobile';
};
