import dayjs, { Dayjs } from 'dayjs';

import { ChannelSummary } from './Channel';
import { GroupList } from './Group';
import { Stats } from './Stats';
import { VideoList, VideoType, VideoTypes } from './Video';

export type MemberStatus = 'online' | 'premiere' | 'offline';

export class Member {
  public id: string = '';
  public name: string = '';
  public shortName: string = '';
  public description: string = '';
  public status: MemberStatus = 'offline';
  public channels: ChannelSummary[] = [];
  public twitterUsername: string = '';
  public liveVideos: VideoList = new VideoList();
  public upcomingVideos: VideoList = new VideoList();
  public groups: GroupList = new GroupList();
  public lastOnlineTime: Dayjs | null = null;
  public lastLoginTime: Dayjs | null = null;
  public videoTypes: VideoTypes = new VideoTypes();
  public stats: Stats = new Stats();
  public onlineHistogram: Histogram = new Histogram();
  public monthlyStats: MonthlyStat[] = [];

  constructor(init?: Partial<Member>) {
    Object.assign(this, init);
  }

  public static fromJSON(data: { [key: string]: any }) {
    return new Member({
      id: data.id,
      name: data.name,
      shortName: data.shortName,
      description: data.description,
      status: data.status,
      channels: data.channels.map((channel: { [key: string]: any }) => ChannelSummary.fromJSON(channel)),
      twitterUsername: data.twitterUsername,
      liveVideos: VideoList.fromItemsJSON(data.liveVideos),
      upcomingVideos: VideoList.fromItemsJSON(data.upcomingVideos),
      groups: GroupList.fromItemsJSON(data.groups),
      lastOnlineTime: data.lastOnlineTime !== null ? dayjs(data.lastOnlineTime) : null,
      lastLoginTime: data.lastLoginTime !== null ? dayjs(data.lastLoginTime) : null,
      videoTypes: data.videoTypes,
      stats: Stats.fromJSON(data.stats),
      onlineHistogram: Histogram.fromItemsJSON(data.onlineHistogram),
      monthlyStats: data.monthlyStats.map((monthlyStat: { [key: string]: any }) => MonthlyStat.fromJSON(monthlyStat)),
    });
  }

  get twitterUrl() {
    return `https://twitter.com/${this.twitterUsername}`;
  }

  get liveVideoUrl() {
    return this.liveVideos.items[0]?.url;
  }
}

export class MemberSummary {
  public id: string = '';
  public name: string = '';
  public shortName: string = '';
  public status: MemberStatus = 'offline';
  public lastOnlineTime: Dayjs | null = null;
  public lastLoginTime: Dayjs | null = null;
  public liveVideoUrl: string | null = null;
  public scheduledStartTime: Dayjs | null = null;
  public scheduledVideoUrl: string | null = null;
  public scheduledVideoType: VideoType | null = null;

  constructor(init?: Partial<MemberSummary>) {
    Object.assign(this, init);
  }

  get isActive() {
    return this.status === 'online' || this.status === 'premiere';
  }

  get isComingOnlineSoon() {
    if (this.scheduledStartTime === null) {
      return false;
    }

    if (
      // 60分以内に配信開始予定かどうか
      dayjs().isAfter(this.scheduledStartTime.add(-60, 'minutes')) &&
      // 配信忘れ等については60分後まで表示する
      dayjs().isBefore(this.scheduledStartTime.add(60, 'minutes'))
    ) {
      return true;
    }

    return false;
  }

  get statusWithOnlineSoon() {
    if (this.status !== 'offline') {
      return this.status;
    }

    return this.isComingOnlineSoon ? 'online_soon' : 'offline';
  }

  public static fromJSON(data: { [key: string]: any }) {
    return new MemberSummary({
      id: data.id,
      name: data.name,
      shortName: data.shortName,
      status: data.status,
      lastOnlineTime: data.lastOnlineTime !== null ? dayjs(data.lastOnlineTime) : null,
      lastLoginTime: data.lastLoginTime !== null ? dayjs(data.lastLoginTime) : null,
      liveVideoUrl: data.liveVideoUrl,
      scheduledStartTime: data.scheduledStartTime !== null ? dayjs(data.scheduledStartTime) : null,
      scheduledVideoUrl: data.scheduledVideoUrl,
      scheduledVideoType: data.scheduledVideoType,
    });
  }
}

export class MemberSummaryList {
  public items: MemberSummary[] = [];

  constructor(init?: Partial<MemberSummaryList>) {
    Object.assign(this, init);
  }

  public static fromItemsJSON(items: { [key: string]: any }[]) {
    return new MemberSummaryList({
      items: items.map((item: any) => MemberSummary.fromJSON(item)),
    });
  }

  get onlineMembers() {
    const items = this.items.filter((user) => ['online', 'premiere'].includes(user.statusWithOnlineSoon));
    return new MemberSummaryList({ items });
  }

  get offlineMembers() {
    const items = this.items.filter((user) => ['offline'].includes(user.statusWithOnlineSoon));
    return new MemberSummaryList({ items });
  }

  get soonOnlineMembers() {
    const items = this.items.filter((user) => ['online_soon'].includes(user.statusWithOnlineSoon));
    return new MemberSummaryList({ items });
  }

  get count() {
    return this.items.length;
  }

  filterByStatus(statuses: MemberStatus[]) {
    return new MemberSummaryList({ items: this.items.filter((user) => statuses.includes(user.status)) });
  }

  /**
   * 配信中動画の最終ログイン時刻でソートする（配信中メンバーのソートに利用）
   */
  sortByLiveVideosLastLoginTime() {
    const items = this.items.sort((a, b) => {
      const aLastLoginTime = a.lastLoginTime;
      const bLastLoginTime = b.lastLoginTime;
      if (aLastLoginTime !== null && bLastLoginTime !== null) {
        return aLastLoginTime.isAfter(bLastLoginTime) ? -1 : 1;
      } else if (aLastLoginTime !== null) {
        return -1;
      } else {
        return 1;
      }
    });
    return new MemberSummaryList({ items });
  }

  /**
   * 最終ログアウト時刻でソートする（オフラインメンバーのソートに利用）
   */
  sortByLiveVideosLastOnlineTime() {
    const items = this.items.sort((a, b) => {
      const aLastOnlineTime = a.lastOnlineTime;
      const bLastOnlineTime = b.lastOnlineTime;
      if (aLastOnlineTime !== null && bLastOnlineTime !== null) {
        return aLastOnlineTime.isAfter(bLastOnlineTime) ? -1 : 1;
      } else if (aLastOnlineTime !== null) {
        return -1;
      } else {
        return 1;
      }
    });
    return new MemberSummaryList({ items });
  }

  /**
   * 予定時刻の昇順でソートする（もうすぐオンラインメンバーのソートに利用）
   */
  sortByScheduledStartTime() {
    const items = this.items.sort((a, b) => {
      const aScheduledStartTime = a.scheduledStartTime;
      const bScheduledStartTime = b.scheduledStartTime;
      if (aScheduledStartTime !== null && bScheduledStartTime !== null) {
        return aScheduledStartTime.isAfter(bScheduledStartTime) ? 1 : -1;
      } else if (aScheduledStartTime !== null) {
        return 1;
      } else {
        return -1;
      }
    });
    return new MemberSummaryList({ items });
  }
}

export class Histogram {
  public items: number[] = Array(24).fill(0);

  constructor(init?: Partial<Histogram>) {
    Object.assign(this, init);
  }

  public static fromItemsJSON(items: number[]) {
    return new Histogram({
      items,
    });
  }
}

export class MonthlyStat {
  public label: string = '';
  public videoTypes: VideoTypes = new VideoTypes();
  public stats: Stats = new Stats();
  public onlineHistogram: Histogram = new Histogram();

  constructor(init?: Partial<MonthlyStat>) {
    Object.assign(this, init);
  }

  public static fromJSON(data: { [key: string]: any }) {
    return new MonthlyStat({
      label: data.label,
      videoTypes: data.videoTypes,
      stats: Stats.fromJSON(data.stats),
      onlineHistogram: Histogram.fromItemsJSON(data.onlineHistogram),
    });
  }
}
