import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { ChannelType, PublishedVideoType } from '../../types';
import { VideoPageTemplate } from '../templates/VideoPageTemplate';

export const VideoPage: React.FC = () => {
  const [urlSearchParams] = useSearchParams();
  const publishedVideoType = urlSearchParams.get('type') || 'all';
  const channelType = urlSearchParams.get('ctype') || 'member';

  if (!['archive', 'video', 'all'].includes(publishedVideoType)) {
    return <Navigate to="/" replace={true} />;
  }

  if (!['member', 'kirinuki'].includes(channelType)) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <>
      <VideoPageTemplate
        publishedVideoType={publishedVideoType as PublishedVideoType}
        channelType={channelType as ChannelType}
      />
    </>
  );
};
