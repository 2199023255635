import { useEffect } from 'react';

export const usePrefetchImages = (urls: string[]) =>
  useEffect(() => {
    const prefetchImages = urls.map((url) => {
      const image = new Image();
      image.src = url;
      return image;
    });

    return () => {
      // 実行中のプリフェッチをキャンセル
      prefetchImages.forEach((image) => (image.src = ''));
    };
  }, [urls]);
