import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import styled from 'styled-components';

export const AboutPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>アツクラLIVE! について</title>
      </Helmet>
      <Wrapper>
        <H1>アツクラLIVE! について</H1>
        <Paragraph>
          <br />
          アツクラLIVE!は、総勢20名のYouTuberが生活するマインクラフトサーバー「アツクラ」の非公式のポータルサイトです。
          <br />
          各メンバーがYouTubeで行うライブ配信や、動画に手軽にアクセスできます。
        </Paragraph>
        <H2>LIVEモード</H2>
        <Paragraph>
          <Link to="/live">LIVEモード</Link>
          はメンバーのステータス（ライブ配信中かどうか）のみを表示するモードです。
          <br />
          アツクラLIVE!自体は、単純にこの機能が作りたくて作り始めました、元祖。
          <br />
          <br />
          右上の歯車アイコンから細かく見た目の設定を変更できるので、自分好みにカスタマイズしてデスクトップの片隅に置いておいたり、iPadに表示するなりするなどして自由にご利用ください。
        </Paragraph>
        <H2>仕組み</H2>
        <Paragraph>
          定期的にアツクラ各メンバーの動画情報をチェックし、
          <b>タイトルに「アツクラ」や一部特定のワードが入っている動画・配信</b>
          のみをピックアップして情報を整理しています。
          <br />
          アツクラ動画だけどタイトルに「アツクラ」という文字が入っていなければアツクラLIVE!には表示されません。
          <br />
          <br />
          切り抜き動画に関しては、上記に加えて、<b>概要欄に元の動画のURLが記載されている</b>
          場合のみ、切り抜き動画としてピックアップされます。
          <br />
          これは、アツクラ関連動画でないにも関わらず、動画タイトルにアツクラと記載されていることによって、アツクラLIVE!に表示されてしまうことを防止する目的でチェックしています。
          <br />
          <br />
          YouTubeのAPIの1日の利用制限のため、ステータスの反映が、夕方〜夜は最大5分程度、それ以外の時間は最大10分程度遅れることがあります。
          <br />
          （YouTube APIの利用数を増やせるように申請しているので、通ればもう少しリアルタイム性を上げることができます。）
          <br />
          <br />
          同様の理由により、切り抜き動画情報の情報の反映は最大60分遅れることがあります。
        </Paragraph>
        <H2>Twitterアカウント</H2>
        <Paragraph>
          アツクラメンバーの配信開始や動画公開情報をツイートするTwitterアカウントも運用しています。
          ライブ配信の開始をリアルタイムに知りたい方はフォローお願いします！
        </Paragraph>
        <TwitterContainer>
          <TwitterTimelineEmbed
            sourceType="profile"
            screenName="atsukurabot"
            noFooter={true}
            theme="dark"
            autoHeight={true}
            options={{ id: 'profile:atsukurabot' }}
          />
        </TwitterContainer>
        <H2>今後やりたいこと</H2>
        <Ul>
          <Li>
            <b>アツクラが継続して盛り上がるようにできることを探してやり続けていきたい</b>
          </Li>
        </Ul>
        <H2>問い合わせ</H2>
        <Paragraph>
          中の人のTwitterアカウント
          <SocialLink href="https://x.com/oyaniwatori">
            <SocialLinkLabel>親ニワトリ🐓 X（旧Twitter）@oyaniwatori</SocialLinkLabel>
          </SocialLink>
          へDMしてください。
        </Paragraph>
        <H2 id="privacypolicy">プライバシーポリシー</H2>
        <SubParagraph>
          当サイトでは、利便性をあげるために、Cookie・アクセス解析・統計データなどを保存、利用します。また Cookie や
          javascript などの技術を利用し、利用者の方の行動の履歴を取得することがあります。
          <br />
          なお、これらには個人情報は含まれません。また、保存されたデータはサービスの改善のみを目的として利用しており、いかなる第三者と共有されることはありません。
          <br />
          <br />
          <br />
          当サービスはYouTube APIを利用して動画の情報を収集し、以下の方法で利用します。
          <br />
          <br />
          <H3>1. 収集する情報:</H3>
          <ThinUl>
            <Li>動画のタイトル、説明、視聴回数、評価などのメタデータ</Li>
            <Li>収集方法: YouTube API</Li>
          </ThinUl>
          <H3>2. 情報の利用目的:</H3>
          <ThinUl>
            <Li>ユーザーに対しておすすめの動画を提供するため</Li>
          </ThinUl>
          <H3>3. 情報の保存:</H3>
          <ThinUl>
            <Li>データは安全なクラウドストレージに保存されます。</Li>
          </ThinUl>
          <H3>4. 第三者との共有:</H3>
          <ThinUl>
            <Li>当サービスは収集したデータを第三者と共有しません。</Li>
          </ThinUl>
          <H3>5. ユーザーの権利:</H3>
          <ThinUl>
            <Li>ユーザーは自身のデータの確認、修正、削除を要求する権利があります。</Li>
            <Li>
              お問い合わせ先:{' '}
              <SocialLink href="https://x.com/oyaniwatori">
                <SocialLinkLabel>親ニワトリ🐓 X（旧Twitter）@oyaniwatori</SocialLinkLabel>
              </SocialLink>
              までDMで連絡ください &nbsp;
            </Li>
          </ThinUl>
          YouTube APIサービスは、Google社のプライバシーポリシー、YouTubeの利用規約に基づいて提供されています。
          <br />
          YouTubeの利用規約、Googleプライバシーポリシーについては以下をご覧ください。当社ウェブサイトを利用するすべてのお客様は、YouTubeの利用規約に同意したものとみなします。
          <br />
          <br />
          YouTube利用規約{' '}
          <a href="https://www.youtube.com/t/terms" style={{ color: 'lightgray', textDecoration: 'underline' }}>
            https://www.youtube.com/t/terms
          </a>
          <br />
          Googleプライバシーポリシー{' '}
          <a href="https://policies.google.com/privacy" style={{ color: 'lightgray', textDecoration: 'underline' }}>
            https://policies.google.com/privacy
          </a>
        </SubParagraph>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  padding-bottom: 96px;

  a {
    text-decoration: underline;
  }

  b {
    color: #ff9900;
  }
`;

const H1 = styled.h1`
  margin-top: 48px;
  margin-bottom: 12px;
`;

const H2 = styled.h2`
  margin-bottom: 12px;
`;
const H3 = styled.h3`
  margin-bottom: 4px;
`;

const Paragraph = styled.p`
  margin-bottom: 48px;
  padding: 12px;
`;

const SubParagraph = styled(Paragraph)`
  font-size: 14px;
  color: 'lightgray';
`;

const TwitterContainer = styled.div`
  width: 100%;
  max-width: 640px;
  height: 400px;
  margin-bottom: 48px;
`;

const Ul = styled.ul`
  margin-bottom: 48px;
`;
const ThinUl = styled.ul`
  margin-bottom: 24px;
`;

const Li = styled.li`
  list-style-type: disc;
  list-style-position: inside;
  text-indent: -20px;
  padding-left: 20px;
`;

const SocialLink = styled.a.attrs(() => ({ target: '_blank' }))``;
const SocialLinkLabel = styled.span`
  height: 24px;
  line-height: 1.6;
  margin-left: 4px;
`;
