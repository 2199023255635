import React, { useEffect, useMemo, useRef, useState } from 'react';
import Spinner from 'react-spinkit';
import VisibilitySensor from 'react-visibility-sensor';
import styled from 'styled-components';

import { Video } from '../../../models/Video';
import { ChannelType } from '../../../types';
import { GroupedVideos, VideoCardList } from '../VideoCardList/VideoCardList';

export type VideoCardListLoaderProps = {
  className?: string;
  channelType?: ChannelType;
  memberIdOrAllOrForMembers?: string;
  videoTypeOrVideoStatus?: 'published' | 'published/live' | 'published/video';
  emptyPlaceholder?: React.ReactNode;
  limitItems?: number | null; // 表示するアイテム数の制限
  limitPages?: number | null; // 読み込みできるページ数のリミット
  moreLink?: React.ReactNode;
  displayDate?: boolean;
};

export const VideoCardListLoader: React.FC<VideoCardListLoaderProps> = React.memo(
  ({
    className,
    channelType = 'member',
    memberIdOrAllOrForMembers,
    videoTypeOrVideoStatus = 'published',
    emptyPlaceholder,
    limitItems = null,
    limitPages = null,
    moreLink,
    displayDate = false,
  }) => {
    const pageCount = useRef(0);
    const [page, setPage] = useState<string>('latest');
    const [displayNextPageLoader, setDisplayNextPageLoader] = useState<boolean>(false);
    const nextPage = useRef<string | null>(null);
    const [videos, setVideos] = useState<Video[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
      (async () => {
        // ローダーを非表示にする
        setDisplayNextPageLoader(false);
        setIsLoading(true);
        const path = channelType === 'member' ? 'videos' : 'kirinuki_videos';
        const response = await fetch(`/api/${path}/${memberIdOrAllOrForMembers}/${videoTypeOrVideoStatus}/${page}`);
        const data = await response.json();
        const additionalVideos = data.items.map((videoJson: any) => Video.fromJSON(videoJson, data.relatedItems));
        setVideos((videos) => {
          const currentVideoIds = new Set(videos.map((video) => video.id));
          const filteredAdditionalVideos = additionalVideos.filter((video: any) => !currentVideoIds.has(video.id));
          return [...videos, ...filteredAdditionalVideos];
        });
        nextPage.current = data.nextPage;
        setDisplayNextPageLoader(data.nextPage !== null);
        setIsLoading(false);
      })();
    }, [memberIdOrAllOrForMembers, videoTypeOrVideoStatus, page, channelType]);

    const showNextPageLoader = useMemo(
      () =>
        displayNextPageLoader &&
        (limitItems == null || videos.length < limitItems) &&
        (limitPages == null || pageCount.current < limitPages),
      [displayNextPageLoader, limitItems, limitPages, videos.length],
    );

    const filteredVideos = useMemo(
      () => (limitItems === null ? videos : videos.slice(0, limitItems)),
      [limitItems, videos],
    );

    /** 動画をグルーピングする(日付グループか、デフォルトグループ) */
    const groups: GroupedVideos[] = useMemo(() => {
      if (!displayDate) {
        return [{ label: '', videos: filteredVideos }];
      }

      return Object.entries(
        filteredVideos.reduce((current, video) => {
          const startTime = video.startTime;
          if (startTime === null) {
            return current;
          }

          const dateStr = startTime.format('YYYY年MM月DD日（dd）');
          const updatedVideos = current[dateStr] || [];
          updatedVideos.push(video);
          current[dateStr] = updatedVideos;
          return current;
        }, {} as { [key: string]: Video[] }),
      )
        .map(([label, videos]) => ({ label, videos }))
        .sort((a, b) => (a === b ? 0 : a > b ? -1 : 1));
    }, [filteredVideos, displayDate]);

    return (
      <Wrapper className={className}>
        <VideoCardList groups={groups} displayDate={displayDate} />
        {showNextPageLoader && (
          <VisibilitySensor
            partialVisibility={true}
            onChange={(isVisible: boolean) => {
              if (isVisible && nextPage.current) {
                setPage(nextPage.current);
              }
            }}
          >
            <NextPageLoader />
          </VisibilitySensor>
        )}
        {!isLoading && filteredVideos.length === 0 && emptyPlaceholder != null && <>{emptyPlaceholder}</>}
        {isLoading && (
          <SpinnerWrapper>
            <Spinner name="line-scale" color="#f1f1f1cc" fadeIn="half" />
          </SpinnerWrapper>
        )}
        {!isLoading && limitItems !== null && videos.length > limitItems && moreLink != null && <>{moreLink}</>}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  position: relative;
`;

const NextPageLoader = styled.div`
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 400px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 84px;
`;
