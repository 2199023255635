import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import 'dayjs/locale/ja';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { ProSidebarProvider, useProSidebar } from 'react-pro-sidebar';
import {
  Navigate,
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
  /* useLocation*/
} from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css';
import smoothscroll from 'smoothscroll-polyfill';

import './_reset.css';
import { ScrollToTop } from './components/atoms/ScrollTop';
import { useScrollLock } from './components/hooks';
import { Layout } from './components/layout/Layout';
// import { AdMax } from './components/molecules/ad/AdMax';
// import { AdWrapper } from './components/molecules/ad/AdWrapper';
import { Header } from './components/organisms/Header';
import { SlideMenu } from './components/organisms/Menu';
import { AboutPage } from './components/pages/AboutPage';
import { AtsukuraGakuenMapPage } from './components/pages/AtsukuraGakuenMapPage';
import { ChangelogPage } from './components/pages/ChangelogPage';
import { KirinukiChannelsPage } from './components/pages/KirinukiChannelsPage';
import { LivePage } from './components/pages/LivePage';
import { MapPage } from './components/pages/MapPage';
import { MemberPage } from './components/pages/MemberPage';
import { MemberVideoPage } from './components/pages/MemberVideoPage';
import { SettingPage } from './components/pages/SettingPage';
import { TopPage } from './components/pages/TopPage';
import { VideoPage } from './components/pages/VideoPage';
import { WidgetManualPage } from './components/pages/WidgetManualPage';
import { WidgetPage } from './components/pages/WidgetPage';
import './index.css';
import reportWebVitals from './reportWebVitals';

// 相対日時のプラグインを有効化
dayjs.extend(relativeTime);
// 日本語で ◯日前 のように表示する
dayjs.locale('ja');

smoothscroll.polyfill();

const LayoutRoute = () => {
  return (
    <Routes>
      <Route path="/videos" element={<VideoListLayout />}>
        <Route path="/videos" element={<VideoPage />} />
      </Route>
      <Route path="/members/:id/videos" element={<VideoListLayout />}>
        <Route path="/members/:id/videos" element={<MemberVideoPage />} />
      </Route>
      <Route path="/" element={<CommonLayout />}>
        <Route path="/" element={<TopPage />} />
        <Route path="/live" element={<LivePage />} />
        <Route path="/members/:id" element={<MemberPage />} />
        <Route path="/setting" element={<SettingPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/changelog" element={<ChangelogPage />} />
        <Route path="/kirinuki_channels" element={<KirinukiChannelsPage />} />
        <Route path="/map" element={<MapPage />} />
        <Route path="/gakuenmap" element={<AtsukuraGakuenMapPage />} />
        <Route path="/widget/manual" element={<WidgetManualPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
};

const CommonLayout = () => {
  // const { pathname } = useLocation();
  return (
    <>
      <Layout>
        <HeaderAndMenu />
        <Layout.Main>
          <Outlet />
          {/* <AdWrapper>
            <AdMax key={`${pathname}_SP_ページの最後`} target="mobile" spot="SP_ページの最後" />
            <AdMax key={`${pathname}_PC_ページの最後`} target="pc" spot="PC_ページの最後" />
          </AdWrapper> */}
        </Layout.Main>
      </Layout>
    </>
  );
};

const VideoListLayout = () => {
  return (
    <>
      <Layout>
        <HeaderAndMenu />
        <Layout.Main>
          <Outlet />
        </Layout.Main>
      </Layout>
    </>
  );
};

const HeaderAndMenu = () => {
  const { toggled } = useProSidebar();
  useScrollLock(toggled);
  const [lockHeader, setLockHeader] = useState(false);
  // const { pathname } = useLocation();
  return (
    <>
      <Layout.AutoHideHeader fixed={lockHeader}>
        <Header />
      </Layout.AutoHideHeader>
      <SlideMenu setLockHeader={setLockHeader} />
      {/* <AdMax key={`${pathname}_SP_オーバーレイ`} target="mobile" spot="SP_オーバーレイ" /> */}
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <HelmetProvider>
    <ProSidebarProvider>
      <Router>
        <ScrollToTop />
        <Layout>
          <Layout.Main>
            <Routes>
              <Route path="/widget" element={<WidgetPage />} />
              <Route path="/*" element={<LayoutRoute />} />
            </Routes>
          </Layout.Main>
        </Layout>
      </Router>
    </ProSidebarProvider>
  </HelmetProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
