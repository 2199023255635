import { useRef } from 'react';
import useSWR from 'swr';

import { AtsukuraLiveData } from '../../models/AtsukuraLiveData';
import { ChannelList } from '../../models/Channel';
import { Member, MemberSummaryList } from '../../models/Member';
import { Stats } from '../../models/Stats';
import { StatusSummary } from '../../models/StatusSummary';

// トップページデータ
type LiveData = {
  status: StatusSummary;
  data: AtsukuraLiveData;
};

const fetchAtukuraLiveData = async ([path, prevData]: [string, React.MutableRefObject<LiveData | undefined>]) => {
  const statusResponse = await fetch(`/api/status/summary?v=${new Date().getTime()}`);
  const status = StatusSummary.fromJSON(await statusResponse.json());
  if (status.lastUpdated.isSame(prevData.current?.status.lastUpdated) && prevData.current) {
    // 更新なしなので前と同じデータを返す
    return prevData.current;
  }

  const dataResponse = await fetch(`${path}?v=${status.lastUpdated.valueOf()}`);
  const data = AtsukuraLiveData.fromJSON(await dataResponse.json());

  return { status, data };
};

export const useLiveData = (refreshInterval: number = 60000) => {
  const prevData = useRef<LiveData | undefined>(undefined);
  const { data, isLoading } = useSWR(['/api/top', prevData], fetchAtukuraLiveData, { refreshInterval });
  prevData.current = data || prevData.current;

  return { data, isLoading };
};

// メンバーページデータ

const fetchMemberData = async ([path, memberId]: [string, string]) => {
  const res = await fetch(`${path}/${memberId}`);
  return Member.fromJSON(await res.json());
};

export const useMemberData = (memberId: string) => useSWR(['/api/members', memberId], fetchMemberData, {});

// ライブページデータ
type MembersData = {
  status: StatusSummary;
  members: MemberSummaryList;
};

const fetchMemberSummaryData = async ([path, prevData]: [string, React.MutableRefObject<MembersData | undefined>]) => {
  const statusResponse = await fetch(`/api/status/summary?v=${new Date().getTime()}`);
  const status = StatusSummary.fromJSON(await statusResponse.json());
  if (status.lastUpdated.isSame(prevData.current?.status.lastUpdated) && prevData.current) {
    // 更新なしなので前と同じデータを返す
    return prevData.current;
  }

  const memberResponse = await fetch(`${path}?v=${status.lastUpdated.valueOf()}`);
  const members = MemberSummaryList.fromItemsJSON(await memberResponse.json());

  return { status, members };
};

export const useMemberSummaryData = (refreshInterval: number = 30000) => {
  const prevData = useRef<MembersData | undefined>(undefined);
  const { data, isLoading } = useSWR(['/api/members_summary/all', prevData], fetchMemberSummaryData, {
    refreshInterval,
  });
  prevData.current = data || prevData.current;
  return { data, isLoading };
};

// メンバーページデータ

const fetchKirinukiChannelsData = async (path: string) => {
  const res = await fetch(path);
  return ChannelList.fromItemsJSON(await res.json());
};

export const useKirinukiChannelsData = () => useSWR('/api/kirinuki_channels', fetchKirinukiChannelsData, {});

// メンバーページデータ

const fetchKirinukiStatsData = async (path: string) => {
  const res = await fetch(path);
  return Stats.fromJSON(await res.json());
};

export const useKirinukiStatsData = () => useSWR('/api/kirinuki_stats', fetchKirinukiStatsData, {});
