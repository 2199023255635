import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Video } from '../../../models/Video';
import { useWindowSize } from '../../hooks';
// import { AdMax } from '../../molecules/ad/AdMax';
// import { AdWrapper } from '../../molecules/ad/AdWrapper';
import { VideoCard } from '../VideoCard/VideoCard';
import { VideoInfo } from '../VideoInfo';

export type GroupedVideos = {
  label: string;
  videos: Video[];
};

type VideoCardListProps = {
  className?: string;
  displayDate?: boolean;
  disableAds?: boolean;
} & ({ videos: Video[]; groups?: GroupedVideos[] } | { videos?: Video[]; groups: GroupedVideos[] });

export const VideoCardList: React.FC<VideoCardListProps> = ({
  className,
  groups,
  videos = [],
  displayDate = false,
  disableAds = false,
}) => {
  const _groups = groups || [{ label: '', videos: videos }];
  const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);
  // const hasMultipleGroups = _groups.length > 1;

  const windowSize = useWindowSize('client');
  const rowCards = calcRowCards(windowSize.width || document.body.clientWidth);

  // let videoCount = 0;
  // let latestAdCount = 0;
  // let adStep = 20;
  return (
    <Wrapper className={className}>
      {_groups.map((group, groupIndex) => {
        const selectedIndex = group.videos.findIndex((video) => selectedVideo?.id === video.id);
        const detailInfoPosition =
          selectedIndex === -1
            ? null
            : Math.min(selectedIndex + (rowCards - (selectedIndex % rowCards)), group.videos.length);

        // const currentAdCount = videoCount - (videoCount % adStep);
        // const showAd = currentAdCount !== latestAdCount;
        // latestAdCount = currentAdCount;
        return (
          <React.Fragment key={group.label}>
            {/* {hasMultipleGroups && showAd && !disableAds && (
              <StyledAdWrapper target="pc">
                <AdMax target="pc" spot="PC_動画一覧内" />
              </StyledAdWrapper>
            )} */}
            {group.label && <SubTitle>{group.label}</SubTitle>}
            <CardListWrapper>
              {group.videos.map((video, index) => {
                // videoCount++;
                return (
                  <React.Fragment key={`${video.id}-${index}`}>
                    <VideoCardWrapper selected={selectedVideo?.id === video.id}>
                      <div id={video.id} style={{ position: 'absolute', top: -50 }} />
                      <StyledVideoCard
                        video={video}
                        selected={selectedVideo?.id === video.id}
                        displayDate={displayDate}
                        onSelect={() => {
                          if (selectedVideo?.id === video.id) {
                            setSelectedVideo(null);
                            return;
                          }
                          setSelectedVideo(video);
                          setTimeout(() => {
                            document.getElementById(video.id)?.scrollIntoView({ block: 'nearest', behavior: 'auto' });
                          }, 0);
                        }}
                      />
                    </VideoCardWrapper>
                    {index + 1 === detailInfoPosition && (
                      <>
                        {Array.from(Array(20).keys()).map((i) => (
                          <EmptyCardWrapper key={`empty-${i}`} />
                        ))}
                        <VideoInfoWrapper>
                          <VideoInfo video={selectedVideo} displayDate={displayDate} />
                        </VideoInfoWrapper>
                      </>
                    )}
                    {/* {videoCount % 10 === 4 && !disableAds && (
                      <StyledAdWrapper target="mobile">
                        <AdMax target="mobile" spot="SP_動画一覧内" />
                      </StyledAdWrapper>
                    )} */}
                  </React.Fragment>
                );
              })}
              {Array.from(Array(20).keys()).map((i) => (
                <EmptyCardWrapper key={`empty-${i}`} />
              ))}
            </CardListWrapper>
          </React.Fragment>
        );
      })}
    </Wrapper>
  );
};

const calcRowCards = (width: number) => {
  if (width <= 430) {
    return 1;
  }
  return Math.ceil((width - 16) / (400 + 8 * 2));
};

const Wrapper = styled.div`
  @media screen and (max-width: 430px) {
    margin-left: -16px;
    margin-right: -16px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const cardWidth = css`
  @media screen and (max-width: 430px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  // n列のカードを表示する場合
  // 400 * n + 16 * (n + 1) の値を max-width に指定する

  @media screen and (min-width: 431px) {
    max-width: 416px;
  }
  @media screen and (min-width: 431px) and (max-width: 848px) {
    width: calc(100% / 2);
  }
  @media screen and (min-width: 849px) and (max-width: 1264px) {
    width: calc(100% / 3);
  }
  @media screen and (min-width: 1265px) and (max-width: 1680px) {
    width: calc(100% / 4);
  }
  @media screen and (min-width: 1681px) and (max-width: 2096px) {
    width: calc(100% / 5);
  }
  @media screen and (min-width: 2097px) and (max-width: 2512px) {
    width: calc(100% / 6);
  }
  @media screen and (min-width: 2513px) and (max-width: 2928px) {
    width: calc(100% / 7);
  }
  @media screen and (min-width: 2929px) and (max-width: 3344px) {
    width: calc(100% / 8);
  }
  @media screen and (min-width: 3345px) and (max-width: 3760px) {
    width: calc(100% / 9);
  }
  @media screen and (min-width: 3761px) and (max-width: 4176px) {
    width: calc(100% / 10);
  }
  @media screen and (min-width: 4177px) and (max-width: 4592px) {
    width: calc(100% / 11);
  }
  @media screen and (min-width: 4593px) and (max-width: 5008px) {
    width: calc(100% / 12);
  }
`;

const VideoCardWrapper = styled.div<{ selected: boolean }>`
  ${cardWidth}
  align-self: stretch;
  margin-bottom: 40px;
  background: ${({ selected }) => (selected ? '#333' : 'transparend')};
  padding: 8px;
  @media screen and (max-width: 430px) {
    padding: 0;
  }

  @media screen and (min-width: 431px) {
    :hover {
      background: ${({ selected }) => (selected ? '#333' : '#1f1f1f')};
    }
  }

  position: relative;
  ::after {
    content: '';
    position: absolute;
    height: 48px;
    width: 100%;
    left: 0;
    background: ${({ selected }) => (selected ? '#333' : 'transparend')};
  }
`;

const StyledVideoCard = styled(VideoCard)`
  height: 100%;
`;

const EmptyCardWrapper = styled.div`
  ${cardWidth}
  margin-bottom: 0px;
  padding: 0 8px;

  @media screen and (max-width: 430px) {
    padding: 0;
  }
`;

const CardListWrapper = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  width: auto;
  margin-top: 20px;

  ${VideoCardWrapper} {
    min-width: 180px;
    min-height: 200px;
  }

  @media screen and (min-width: 431px) {
    margin-left: -8px;
    margin-right: -8px;
  }
`;

const SubTitle = styled.div`
  font-size: 18px;
  margin-top: 20px;

  @media screen and (max-width: 430px) {
    margin-left: 16px;
    margin-right: 16px;
  }
`;

const VideoInfoWrapper = styled.div`
  width: calc(100% + 16px);

  background: #333;
  @media screen and (max-width: 430px) {
    padding: 0 16px;
  }
  @media screen and (min-width: 431px) {
    margin-left: -8px;
    margin-right: -8px;
    padding: 0 16px;
  }
  margin-bottom: 40px;
`;

// const StyledAdWrapper = styled(AdWrapper)`
//   margin: 16px;
//   margin-bottom: 64px;
// `;
