import React from 'react';

type CountProps = {
  value: number;
};

export const Count: React.FC<CountProps> = React.memo(({ value }) => {
  if (value > 1000000000) {
    return <>{(value / 100000000).toFixed()}億</>;
  } else if (value > 100000000) {
    return <>{(value / 100000000).toFixed(1)}億</>;
  } else if (value > 100000) {
    return <>{(value / 10000).toFixed()}万</>;
  } else if (value > 10000) {
    return <>{(value / 10000).toFixed(1)}万</>;
  }
  return <>{value}</>;
});
