import React from 'react';
import { Helmet } from 'react-helmet-async';

import { useMemberSummaryData } from '../hooks/api';
import { LivePageTemplate } from '../templates/LivePageTemplate';

export const LivePage: React.FC = () => {
  const { data, isLoading } = useMemberSummaryData();
  if (!data || isLoading) {
    return (
      <>
        <Helmet>
          <title>LIVEモード | アツクラLIVE!</title>
        </Helmet>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>LIVEモード | アツクラLIVE!</title>
      </Helmet>
      <LivePageTemplate members={data.members} />
    </>
  );
};
