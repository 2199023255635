import styled from 'styled-components';

export const Active = styled.div`
  @keyframes pulseMotion {
    0% {
      transform: translate(-50%, -50%) scale(1, 1);
      background-color: rgba(204, 0, 0, 0.4);
    }
    100% {
      transform: translate(-50%, -50%) scale(2.5, 2.5);
      background-color: rgba(204, 0, 0, 0);
    }
  }

  display: inline-block;
  position: relative;
  width: 10px;
  height: 10px;
  background-color: rgba(204, 0, 0, 0.9);
  border-radius: 50%;
  transition: background-color cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s;
  cursor: pointer;
  vertical-align: middle;

  &:after {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: opacity linear 0.4s;
    content: '';
    animation: pulseMotion 1.4s linear infinite;
  }
`;
