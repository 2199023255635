import { MemberSummaryList } from './Member';
import { Stats } from './Stats';
import { VideoList } from './Video';

export class AtsukuraLiveData {
  liveVideos: VideoList = new VideoList();
  upcomingVideos: VideoList = new VideoList();
  publishedVideos: VideoList = new VideoList();
  stats: Stats = new Stats();
  members: MemberSummaryList = new MemberSummaryList();

  constructor(init?: Partial<AtsukuraLiveData>) {
    Object.assign(this, init);
  }

  public static fromJSON(data: { [key: string]: any }) {
    return new AtsukuraLiveData({
      liveVideos: VideoList.fromItemsJSON(data.liveVideos),
      upcomingVideos: VideoList.fromItemsJSON(data.upcomingVideos),
      publishedVideos: VideoList.fromItemsJSON(data.publishedVideos),
      stats: Stats.fromJSON(data.stats),
      members: MemberSummaryList.fromItemsJSON(data.members),
    });
  }
}
