import React from 'react';
import styled from 'styled-components';

type RecommendationProps = {
  setDisplayRecommendation: (displayRecommendation: boolean) => void;
};

export const Recommendation: React.FC<RecommendationProps> = ({ setDisplayRecommendation }) => {
  return (
    <Wrapper>
      <Image src="/images/image/recommendation.png" />
      <Close onClick={() => setDisplayRecommendation(false)}>今後表示しない</Close>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  text-align: right;
`;
const Image = styled.img`
  width: 100%;
`;
const Close = styled.a`
  color: #999;
  text-decoration: underline;
  font-size: 14px;
  margin-right: 12px;
`;
