import { Dayjs } from 'dayjs';
import React from 'react';
import styled from 'styled-components';

import { Video } from '../../../models/Video';
import { Count } from '../../atoms/Count';
import { FromNow } from '../../atoms/FromNow/FromNow';

export type VideoMetaDataProps = {
  video: Video;
  displayDate?: boolean;
};

export const VideoMetaData: React.FC<VideoMetaDataProps> = React.memo(({ video, displayDate = false }) => {
  let count: number | null = null;
  let countSuffix: string = '';
  let time: Dayjs | null = null;
  let timeSuffix: string = '';

  if (video.isPublished) {
    count = video.stats.viewCount;
    countSuffix = '回視聴';

    if (video.videoType === 'video') {
      time = video.publishedAt;
    } else if (video.videoType === 'live') {
      time = video.liveStreamingDetails.actualEndTime;
      timeSuffix = 'に配信済み';
    } else if (video.videoType === 'premiere') {
      time = video.liveStreamingDetails.actualStartTime;
    }
  } else if (video.isScheduled) {
    count = null;
    time = video.liveStreamingDetails.scheduledStartTime;
    if (video.videoType === 'live') {
      timeSuffix = 'から配信予定';
    } else if (video.videoType === 'premiere') {
      timeSuffix = 'に公開予定';
    }
  } else if (video.isLive) {
    count = video.liveStreamingDetails.concurrentViewers || 0;
    countSuffix = '人が視聴中';
    if (video.videoType === 'live') {
      time = video.liveStreamingDetails.actualStartTime;
    } else if (video.videoType === 'premiere') {
      time = video.liveStreamingDetails.actualStartTime;
    }
    timeSuffix = 'から配信中';
  }

  return (
    <Wrapper>
      {count !== null && (
        <MetaDataItem>
          <Count value={count} />
          {countSuffix}
        </MetaDataItem>
      )}
      {time !== null && (
        <MetaDataItem>
          {!displayDate ? <FromNow time={time} displayAfterTime={true} /> : <>{time.format('M/D HH:mm')}</>}
          {timeSuffix}
        </MetaDataItem>
      )}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  font-size: 12px;
  color: #aaaaaa;
`;

const MetaDataItem = styled.span`
  &:not(:first-child) {
    ::before {
      content: '・';
    }
  }
`;
