import styled from 'styled-components';

type Direction = 'up' | 'right' | 'down' | 'left';

interface Props {
  direction?: Direction;
  color?: string;
  length?: number;
  weight?: number;
}

const DIRECTION_TO_ANGLE: { [key in Direction]: number } = {
  up: -45,
  right: 45,
  down: 135,
  left: -135,
};

const VERTICAL_OFFSET_SCALE: { [key in Direction]: [number, number] } = {
  up: [1 / 4, -1 / Math.sqrt(2) / 2],
  right: [0, 0],
  down: [-1 / 4, 1 / Math.sqrt(2) / 2],
  left: [0, 0],
};

const HORIZONTAL_OFFSET_SCALE: { [key in Direction]: [number, number] } = {
  up: [0, 0],
  right: [-1 / 4, 1 / Math.sqrt(2) / 2],
  down: [0, 0],
  left: [1 / 4, -1 / Math.sqrt(2) / 2],
};

const Arrow = styled.div<Props>`
  position: relative;
  display: inline-block;
  padding: ${({ length }) => length! / 2}px;
  width: ${({ length }) => length}px;
  height: ${({ length }) => length}px;
  ::after {
    position: absolute;
    content: '';
    top: ${({ length, direction, weight }) =>
      (length! - length! / Math.sqrt(2)) / 2 +
      length! * VERTICAL_OFFSET_SCALE[direction!][0] +
      weight! * VERTICAL_OFFSET_SCALE[direction!][1]}px;
    left: ${({ length, direction, weight }) =>
      (length! - length! / Math.sqrt(2)) / 2 +
      length! * HORIZONTAL_OFFSET_SCALE[direction!][0] +
      weight! * HORIZONTAL_OFFSET_SCALE[direction!][1]}px;
    width: ${({ length }) => length! / Math.sqrt(2)}px;
    height: ${({ length }) => length! / Math.sqrt(2)}px;
    border-top: ${({ weight }) => weight}px solid ${({ color }) => color};
    border-right: ${({ weight }) => weight}px solid ${({ color }) => color};
    transform: ${({ direction }) => `rotate(${DIRECTION_TO_ANGLE[direction!]}deg)`};
  }
`;

Arrow.defaultProps = {
  direction: 'right',
  color: '#eee',
  length: 16,
  weight: 4,
};

export { Arrow };
