import React from 'react';
import { Helmet } from 'react-helmet-async';

import { useKirinukiChannelsData } from '../hooks/api';
import { KirinukiChannelsTemplate } from '../templates/KirinukiChannelsTemplate';

export const KirinukiChannelsPage: React.FC = () => {
  const { data, isLoading } = useKirinukiChannelsData();

  if (!data || isLoading) {
    return (
      <>
        <Helmet>
          <title>切り抜きチャンネル一覧 | アツクラLIVE!</title>
        </Helmet>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>切り抜きチャンネル一覧 | アツクラLIVE!</title>
      </Helmet>
      <KirinukiChannelsTemplate channels={data} />
    </>
  );
};
