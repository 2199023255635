import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { MenuButton } from './Menu';

type HeaderProps = {
  className?: string;
  standalone?: boolean;
  showUrl?: boolean;
};

export const Header: React.FC<HeaderProps> = ({ className, standalone = false, showUrl = false }) => {
  const contents = (
    <>
      <HomeIcon />
      <Label>アツクラLIVE!</Label>
      {showUrl && <Label> atsukura.live </Label>}
    </>
  );

  const LinkComponent: any = standalone ? ExternalLink : StyledLink;
  const linkParams = standalone ? { href: '/' } : { to: '/' };

  return (
    <Wrapper className={className}>
      <LinkComponent {...linkParams}>{contents}</LinkComponent>
      <MenuButton />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const ExternalLink = styled.a.attrs(() => ({ target: '_blank' }))`
  display: flex;
  align-items: center;
`;

const HomeIcon = styled.img.attrs(() => ({ src: '/images/icon/home.png' }))`
  width: 32px;
  height: 32px;
`;
const Label = styled.span`
  :not(:first-of-type) {
    margin-left: 12px;
  }
`;
