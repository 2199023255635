import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';

import { PublishedVideoType } from '../../types';
import { useMemberData } from '../hooks/api';
import { VideoPageTemplate } from '../templates/VideoPageTemplate';

export const MemberVideoPage: React.FC = () => {
  const { id: memberId = '' } = useParams();
  const [urlSearchParams] = useSearchParams();
  const publishedVideoType = urlSearchParams.get('type') || '';
  const { data, isLoading } = useMemberData(memberId);

  if (!['archive', 'video', 'kirinuki'].includes(publishedVideoType)) {
    return <Navigate to="/" replace={true} />;
  }

  if (!data || isLoading) {
    return (
      <>
        <Helmet>
          <title>アツクラLIVE!</title>
        </Helmet>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{data.shortName} | アツクラLIVE!</title>
      </Helmet>
      <VideoPageTemplate
        member={data}
        publishedVideoType={publishedVideoType as PublishedVideoType}
        channelType={publishedVideoType === 'kirinuki' ? 'kirinuki' : 'member'}
      />
    </>
  );
};
