import React, { useMemo } from 'react';

import { useWindowSize } from '../../hooks';
import { VideoCardListLoader, VideoCardListLoaderProps } from './VideoCardListLoader';

type AutoLimitVideoCardListLoaderProps = {
  className?: string;
  limitItemsForMobile?: number | undefined; // 表示するアイテム数の制限
} & Omit<VideoCardListLoaderProps, 'limitItems' | 'limitPages'>;

const useAutoLimit = (limitItemsForMobile: number) => {
  const { width } = useWindowSize();
  const limitItems = useMemo(() => {
    if (width <= 430) {
      return limitItemsForMobile;
    }
    return Math.ceil((width - 16) / (400 + 8 * 2));
  }, [limitItemsForMobile, width]);

  return limitItems;
};

export const AutoLimitVideoCardListLoader: React.FC<AutoLimitVideoCardListLoaderProps> = React.memo((props) => {
  const { limitItemsForMobile = 2, ...restProps } = props;

  const limitItems = useAutoLimit(limitItemsForMobile);

  return <VideoCardListLoader limitItems={limitItems} limitPages={1} {...restProps} />;
});
