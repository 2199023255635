import React from 'react';
import styled from 'styled-components';

import i_3chunk from '../../../assets/images/skin-face/3chunk-min.png';
import i_Amaguri from '../../../assets/images/skin-face/Amaguri-min.png';
import i_Dooozle from '../../../assets/images/skin-face/Dooozle-min.png';
import i_HikacGames from '../../../assets/images/skin-face/HikacGames-min.png';
import i_KIOKIOoooo from '../../../assets/images/skin-face/KIOKIOoooo-min.png';
import i_KOHALON from '../../../assets/images/skin-face/KOHALON-min.png';
import i_KOMESHOWGUN from '../../../assets/images/skin-face/KOMESHOWGUN-min.png';
import i_KazuChannel from '../../../assets/images/skin-face/KazuChannel-min.png';
import i_Luzaku from '../../../assets/images/skin-face/Luzaku-min.png';
import i_ORAMAINECRAF from '../../../assets/images/skin-face/ORAMAINECRAF-min.png';
import i_Qnly_qdm from '../../../assets/images/skin-face/Qnly_qdm-min.png';
import i_SAKAI_Thunder from '../../../assets/images/skin-face/SAKAI_Thunder-min.png';
import i_Tyomu_Maro from '../../../assets/images/skin-face/Tyomu_Maro-min.png';
import i_bonj55 from '../../../assets/images/skin-face/bonj55-min.png';
import i_gizokk from '../../../assets/images/skin-face/gizokk-min.png';
import i_jajaankikuchi from '../../../assets/images/skin-face/jajaankikuchi-min.png';
import i_kitakubu from '../../../assets/images/skin-face/kitakubu-min.png';
import i_maeyon from '../../../assets/images/skin-face/maeyon-min.png';
import i_maguro29 from '../../../assets/images/skin-face/maguro29-min.png';
import i_messu009 from '../../../assets/images/skin-face/messu009-min.png';
import i_ooharaMEN from '../../../assets/images/skin-face/ooharaMEN-min.png';
import i_pom52_p from '../../../assets/images/skin-face/pom52_p-min.png';
import i_shyutan from '../../../assets/images/skin-face/shyutan-min.png';
import i_taitai0904 from '../../../assets/images/skin-face/taitai0904-min.png';

const SKIN_FACE_URLS: { [key: string]: string } = {
  '3chunk': i_3chunk,
  Amaguri: i_Amaguri,
  Dooozle: i_Dooozle,
  HikacGames: i_HikacGames,
  KIOKIOoooo: i_KIOKIOoooo,
  KOHALON: i_KOHALON,
  KOMESHOWGUN: i_KOMESHOWGUN,
  KazuChannel: i_KazuChannel,
  Luzaku: i_Luzaku,
  ORAMAINECRAF: i_ORAMAINECRAF,
  Qnly_qdm: i_Qnly_qdm,
  SAKAI_Thunder: i_SAKAI_Thunder,
  Tyomu_Maro: i_Tyomu_Maro,
  bonj55: i_bonj55,
  gizokk: i_gizokk,
  jajaankikuchi: i_jajaankikuchi,
  kitakubu: i_kitakubu,
  maeyon: i_maeyon,
  maguro29: i_maguro29,
  messu009: i_messu009,
  ooharaMEN: i_ooharaMEN,
  pom52_p: i_pom52_p,
  taitai0904: i_taitai0904,
  shyutan: i_shyutan,
};

type SkinFaceProps = {
  className?: string;
  nameTag: string;
  size?: number | string;
  status?: 'online' | 'offline';
};

export const SkinFace: React.FC<SkinFaceProps> = React.memo(({ className, nameTag, size, status = 'online' }) => {
  const imageUrl = SKIN_FACE_URLS[nameTag];
  return <Image className={className} src={imageUrl} size={size} status={status} />;
});

const Image = styled.img<{ size?: number | string; status: 'online' | 'offline' }>`
  display: block;
  width: ${({ size }) => (size === undefined ? '100%' : typeof size === 'number' ? `${size}px` : size)};
  height: ${({ size }) => (size === undefined ? '100%' : typeof size === 'number' ? `${size}px` : size)};

  filter: ${({ status }) => (status === 'online' ? 'none' : 'grayscale(1)')};
`;

/**
 * アツクラ学園用スキンフェイス
 */
export const AtsukuraGakuennSkinFace: React.FC<SkinFaceProps> = React.memo(
  ({ className, nameTag, size, status = 'online' }) => {
    const imageUrl = SKIN_FACE_URLS[nameTag];
    const gakuenn: string | undefined = {
      '3chunk': '南',
      Amaguri: '南',
      Dooozle: '北',
      HikacGames: '北',
      KIOKIOoooo: '北',
      KOHALON: '北',
      KOMESHOWGUN: '南',
      KazuChannel: '南',
      Luzaku: '南',
      ORAMAINECRAF: '南',
      Qnly_qdm: '南',
      SAKAI_Thunder: '南',
      Tyomu_Maro: '南',
      bonj55: '北',
      gizokk: '北',
      jajaankikuchi: '北',
      kitakubu: '北',
      maeyon: '北',
      maguro29: '南',
      messu009: '南',
      ooharaMEN: '北',
      pom52_p: '北',
      taitai0904: '北',
    }[nameTag];
    const borderColor: string | undefined = {
      北: '#cc0000',
      南: '#3c78d8',
    }[gakuenn ?? ''];
    const borderWidth = '7%';
    return (
      <ImageWithBorderWrapper className={className} size={size} status={status} borderColor={borderColor}>
        <ImageWithBorder className={className} src={imageUrl} size={size} status={status} borderWidth={borderWidth} />
      </ImageWithBorderWrapper>
    );
  },
);

const ImageWithBorderWrapper = styled.div<{
  size?: number | string;
  status: 'online' | 'offline';
  borderColor?: string;
}>`
  position: relative;
  display: block;
  width: ${({ size }) => (size === undefined ? '100%' : typeof size === 'number' ? `${size}px` : size)};
  height: ${({ size }) => (size === undefined ? '100%' : typeof size === 'number' ? `${size}px` : size)};
  background-color: ${({ borderColor }) => borderColor};
  filter: ${({ status }) => (status === 'online' ? 'none' : 'grayscale(0.5)')};
`;

const ImageWithBorder = styled.img<{
  size?: number | string;
  status: 'online' | 'offline';
  label?: string;
  borderWidth: string;
}>`
  position: absolute;
  display: flex;
  left: ${({ borderWidth }) => borderWidth};
  top: ${({ borderWidth }) => borderWidth};
  display: block;
  width: ${({ borderWidth }) => `calc(100% - ${borderWidth} * 2)`};
  height: ${({ borderWidth }) => `calc(100% - ${borderWidth} * 2)`};

  filter: ${({ status }) => (status === 'online' ? 'none' : 'grayscale(1)')};
`;
