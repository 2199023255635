import React from 'react';
import styled from 'styled-components';

import { ConfigurableMemberStatusOptions } from '../MemberStatus';

type MemberStatusSettingProps = {
  className?: string;
  options: ConfigurableMemberStatusOptions;
  onChangeOptions: (options: ConfigurableMemberStatusOptions) => void;
};

export const MemberStatusSetting: React.FC<MemberStatusSettingProps> = ({ options, onChangeOptions }) => {
  return (
    <Wrapper>
      <SettingItem>
        <SettingItemLabel>列数</SettingItemLabel>
        <Value>{options.rows}</Value>
        <Slider
          type="range"
          value={options.rows}
          min={1}
          max={20}
          onChange={(event) => {
            const value = parseInt(event.target.value);
            onChangeOptions({ ...options, rows: value });
          }}
        />
      </SettingItem>
      <SettingItem>
        <SettingItemLabel>間隔</SettingItemLabel>
        <Value>{options.gap}</Value>
        <Slider
          type="range"
          value={(options.gap || 0) * 10}
          min={0}
          max={100}
          onChange={(event) => {
            const value = parseInt(event.target.value);
            onChangeOptions({ ...options, gap: value / 10 });
          }}
        />
      </SettingItem>
      <SettingItem>
        <SettingItemLabel>余白</SettingItemLabel>
        <Value>{options.padding}</Value>
        <Slider
          type="range"
          value={(options.padding || 0) * 10}
          min={0}
          max={200}
          onChange={(event) => {
            const value = parseInt(event.target.value);
            onChangeOptions({ ...options, padding: value / 10 });
          }}
        />
      </SettingItem>
      <hr />
      <SettingItem>フォントサイズ</SettingItem>
      <SettingItem>
        <SettingItemSubLabel>グループ名</SettingItemSubLabel>
        <Value>{options.groupNameFontSize}</Value>
        <Slider
          type="range"
          value={(options.groupNameFontSize || 0) * 10}
          min={0}
          max={100}
          onChange={(event) => {
            const value = parseInt(event.target.value);
            onChangeOptions({ ...options, groupNameFontSize: value / 10 });
          }}
        />
      </SettingItem>
      <SettingItem>
        <SettingItemSubLabel>メンバー名</SettingItemSubLabel>
        <Value>{options.memberNameFontSize}</Value>
        <Slider
          type="range"
          value={(options.memberNameFontSize || 0) * 10}
          min={0}
          max={100}
          onChange={(event) => {
            const value = parseInt(event.target.value);
            onChangeOptions({ ...options, memberNameFontSize: value / 10 });
          }}
        />
      </SettingItem>
      <hr />
      <SettingItem>
        直接YouTube動画に遷移する
        <input
          type="checkbox"
          checked={options.directVideoLink}
          style={{ width: 20 }}
          onChange={(event) => {
            onChangeOptions({ ...options, directVideoLink: event.target.checked });
          }}
        />
      </SettingItem>
      <SettingItem>
        オフラインの場合クリック無効
        <input
          type="checkbox"
          checked={options.disableOfflineMemberClick}
          style={{ width: 20 }}
          onChange={(event) => {
            onChangeOptions({ ...options, disableOfflineMemberClick: event.target.checked });
          }}
        />
      </SettingItem>
      <SettingItem>
        プレミア公開ラベルの表示
        <input
          type="checkbox"
          checked={options.showPremiereLabel}
          style={{ width: 20 }}
          onChange={(event) => {
            onChangeOptions({ ...options, showPremiereLabel: event.target.checked });
          }}
        />
      </SettingItem>
      <SettingItem>
        配信開始予定ラベルの表示
        <input
          type="checkbox"
          checked={options.showScheduleLabel}
          style={{ width: 20 }}
          onChange={(event) => {
            onChangeOptions({ ...options, showScheduleLabel: event.target.checked });
          }}
        />
      </SettingItem>
      <hr />
      <SettingItem>オフラインのメンバーの表示</SettingItem>
      <SettingItem>
        <div>
          <input
            id="offline-visibility-all"
            type="radio"
            value="all"
            checked={options.offlineVisibility === 'all'}
            style={{ width: 20 }}
            onChange={(event) => {
              onChangeOptions({ ...options, offlineVisibility: event.target.value as any });
            }}
          />
          <label htmlFor="offline-visibility-all">すべて</label>
        </div>
        <div>
          <input
            id="offline-visibility-scheduled"
            type="radio"
            value="scheduled"
            checked={options.offlineVisibility === 'scheduled'}
            style={{ width: 20 }}
            onChange={(event) => {
              onChangeOptions({ ...options, offlineVisibility: event.target.value as any });
            }}
          />
          <label htmlFor="offline-visibility-scheduled">もうすぐ配信開始のみ</label>
        </div>
        <div>
          <input
            id="offline-visibility-hidden"
            type="radio"
            value="hidden"
            checked={options.offlineVisibility === 'hidden'}
            style={{ width: 20 }}
            onChange={(event) => {
              onChangeOptions({ ...options, offlineVisibility: event.target.value as any });
            }}
          />
          <label htmlFor="offline-visibility-hidden">なし</label>
        </div>
      </SettingItem>
      <SettingItem>オフラインのメンバーのスタイル</SettingItem>
      <SettingItem>
        <div>
          <input
            id="offline-style-normal"
            type="radio"
            value="normal"
            checked={options.offlineStyle === 'normal'}
            style={{ width: 20 }}
            onChange={(event) => {
              onChangeOptions({ ...options, offlineStyle: event.target.value as any });
            }}
          />
          <label htmlFor="offline-style-normal">カラー</label>
        </div>
        <div>
          <input
            id="offline-style-grayscale"
            type="radio"
            value="grayscale"
            checked={options.offlineStyle === 'grayscale'}
            style={{ width: 20 }}
            onChange={(event) => {
              onChangeOptions({ ...options, offlineStyle: event.target.value as any });
            }}
          />
          <label htmlFor="offline-style-grayscale">グレースケール</label>
        </div>
      </SettingItem>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const SettingItem = styled.div`
  display: flex;
  margin: 12px 12px;
  justify-content: space-between;
`;
const SettingItemLabel = styled.div`
  width: 120px;
`;
const SettingItemSubLabel = styled.div`
  width: 100px;
  margin-left: 20px;
`;

const Value = styled.div`
  width: 30px;
  text-align: right;
  margin-right: 4px;
`;
const Slider = styled.input`
  width: 120px;
  flex-grow: 1;
`;
