import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import { useMemberData } from '../hooks/api';
import { MemberPageTemplate } from '../templates/MemberPageTemplate';

export const MemberPage: React.FC = () => {
  const { id: memberId = '' } = useParams();
  const { data, isLoading } = useMemberData(memberId);

  if (!data || isLoading) {
    return (
      <>
        <Helmet>
          <title>アツクラLIVE!</title>
        </Helmet>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{data.shortName} | アツクラLIVE!</title>
      </Helmet>
      <MemberPageTemplate member={data} />
    </>
  );
};
