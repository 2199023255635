import dayjs, { Dayjs } from 'dayjs';

export class StatusSummary {
  public lastUpdated: Dayjs = dayjs();

  constructor(init?: Partial<StatusSummary>) {
    Object.assign(this, init);
  }

  public static fromJSON(data: { [key: string]: any }) {
    return new StatusSummary({
      lastUpdated: dayjs(data.lastUpdated),
    });
  }
}
