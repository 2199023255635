import dayjs, { Dayjs } from 'dayjs';

import { Stats } from './Stats';

export class Channel {
  public id: string = '';
  public title: string = '';
  public description: string = '';
  public customUrl: string = '';
  public thumbnail: string = '';
  public publishedAt: Dayjs = dayjs();
  public stats: Stats = new Stats();

  constructor(init?: Partial<Channel>) {
    Object.assign(this, init);
  }

  public static fromJSON(data: { [key: string]: any }) {
    return new Channel({
      id: data.id,
      title: data.title,
      description: data.description,
      customUrl: data.customUrl,
      thumbnail: data.thumbnail,
      publishedAt: dayjs(data.publishedAt),
      stats: Stats.fromJSON(data.stats),
    });
  }

  get url() {
    return `https://www.youtube.com/${this.customUrl}`;
  }

  get hasVideo() {
    return this.stats.totalVideoCount > 0;
  }
}

export class ChannelList {
  items: Channel[] = [];

  constructor(init?: Partial<ChannelList>) {
    Object.assign(this, init);
  }

  public static fromItemsJSON(items: { [key: string]: any }[]) {
    return new ChannelList({
      items: items.map((item: any) => Channel.fromJSON(item)),
    });
  }
}

export class ChannelSummary {
  public id: string = '';
  public title: string = '';
  public customUrl: string = '';
  public thumbnail: string = '';

  constructor(init?: Partial<ChannelSummary>) {
    Object.assign(this, init);
  }

  public static fromJSON(data: { [key: string]: any }) {
    return new ChannelSummary({
      id: data.id,
      title: data.title,
      customUrl: data.customUrl,
      thumbnail: data.thumbnail,
    });
  }

  get url() {
    return `https://www.youtube.com/${this.customUrl}`;
  }
}
