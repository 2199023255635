import React from 'react';
import { Helmet } from 'react-helmet-async';

import { Stats } from '../../models/Stats';
import { useKirinukiStatsData, useLiveData } from '../hooks/api';
import { TopPageTemplate } from '../templates/TopPageTemplate';

export const TopPage: React.FC = () => {
  const { data, isLoading } = useLiveData();
  const { data: kirinukiStats = new Stats() } = useKirinukiStatsData();

  if (!data || isLoading) {
    return (
      <>
        <Helmet>
          <title>アツクラLIVE!</title>
        </Helmet>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>アツクラLIVE!</title>
      </Helmet>
      <TopPageTemplate data={data.data} kirinukiStats={kirinukiStats} />
    </>
  );
};
