import React from 'react';
import { Bar, BarChart, ResponsiveContainer, XAxis } from 'recharts';

type OnlineHistogramProps = {
  className?: string;
  values: number[];
};

export const OnlineHistogram: React.FC<OnlineHistogramProps> = React.memo(({ className, values }) => {
  const _data = values.map((value, index) => ({
    name: String(index),
    value,
  }));
  const data = _data.slice(9).concat(_data.slice(0, 9));
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data}>
        <XAxis dataKey="name" tick={{ fill: '#f1f1f1' }} axisLine={{ stroke: '#f1f1f1' }} interval={2} />
        <Bar dataKey="value" fill="#ffffffe6" />
      </BarChart>
    </ResponsiveContainer>
  );
});
