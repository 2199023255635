import React from 'react';

import { useLocalStorage } from '../hooks/storage';

export const SettingPage: React.FC = () => {
  const [isStatsEnabled, setIsStatsEnabled] = useLocalStorage('IS_STATS_ENABLED', false, false);

  return (
    <div>
      <input
        type="checkbox"
        checked={isStatsEnabled}
        onChange={(e) => {
          setIsStatsEnabled(e.target.checked);
        }}
      />{' '}
      統計情報を有効化
    </div>
  );
};
