import React from 'react';
import { Cell, Legend, Pie, PieChart } from 'recharts';
import styled from 'styled-components';

import { VideoTypes } from '../../../models/Video';

type VideoChartProps = {
  className?: string;
  videoTypes: VideoTypes;
  size?: 'large' | 'small';
};

export const VideoChart: React.FC<VideoChartProps> = React.memo((props) => {
  return (props.size ?? 'large') === 'large' ? <VideoChartLarge {...props} /> : <VideoChartSmall {...props} />;
});

const VideoChartLarge: React.FC<VideoChartProps> = React.memo(({ className, videoTypes }) => {
  const data = [
    {
      index: 0,
      name: 'ライブ配信',
      value: videoTypes.live,
      color: 'red',
    },
    {
      index: 1,
      name: 'プレミア公開',
      value: videoTypes.premiere,
    },
    {
      index: 2,
      name: '動画',
      value: videoTypes.video,
    },
  ];
  const colors = ['#cc0000e6', '#cccc33e6', '#3333cce6'];
  return (
    <Wrapper>
      <PieChart width={640} height={360}>
        <Pie
          data={data}
          dataKey="value"
          cx="50%"
          cy="50%"
          outerRadius={100}
          labelLine={renderLabelLineItem}
          label={renderLabelItem}
          isAnimationActive={false}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
      </PieChart>
    </Wrapper>
  );
});

const renderLabelLineItem = (props: any) => {
  return Pie.renderLabelLineItem({}, { ...props, stroke: '#f1f1f1' });
};
const renderLabelItem = ({ x, y, cx, name, value, percent, fill }: any) => {
  const rectSize = 16;
  const offset = 8;
  return (
    <>
      <rect
        x={x > cx ? x + offset : x - (offset + rectSize)}
        y={y - 8}
        fill={fill}
        stroke="#f1f1f1"
        width={rectSize}
        height={rectSize}
      />
      <text
        x={x > cx ? x + (offset * 2 + rectSize) : x - (offset + rectSize)}
        y={y}
        fill="#f1f1f1"
        fontWeight="bold"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${name} ${value}本（${(percent * 100).toFixed(0)}%）`}
      </text>
    </>
  );
};

const VideoChartSmall: React.FC<VideoChartProps> = React.memo(({ className, videoTypes }) => {
  const data = [
    {
      index: 0,
      name: 'ライブ配信',
      value: videoTypes.live,
      color: 'red',
    },
    {
      index: 1,
      name: 'プレミア公開',
      value: videoTypes.premiere,
    },
    {
      index: 2,
      name: '動画',
      value: videoTypes.video,
    },
  ];
  const colors = ['#cc0000e6', '#cccc33e6', '#3333cce6'];
  return (
    <Wrapper>
      <PieChart width={320} height={180}>
        <Pie data={data} dataKey="value" cx="50%" cy="50%" isAnimationActive={false}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Legend
          layout="vertical"
          verticalAlign="middle"
          align="right"
          payload={data.map((item, index) => ({
            id: item.name,
            type: 'square',
            value: `${item.name} ${item.value}本`,
            color: colors[index % colors.length],
          }))}
        />
      </PieChart>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  .recharts-default-legend li {
    margin: 4px 0;
  }

  .recharts-default-legend svg {
    margin-bottom: 4px;
  }

  .recharts-legend-item-text {
    color: #f1f1f1 !important;
    font-weight: normal;
    display: inline-block;
    width: 150px;
  }
`;
