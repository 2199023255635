import React from 'react';
import styled from 'styled-components';

type SkinProps = {
  className?: string;
  nameTag: string;
  size?: number;
};

export const Skin: React.FC<SkinProps> = React.memo(({ className, nameTag, size = 600 }) => {
  const imageUrl = `/images/skin/${nameTag}.png`;
  return <Image className={className} src={imageUrl} size={size} />;
});

const Image = styled.img<{ size: number }>`
  display: block;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  object-fit: contain;
`;
