import dayjs, { Dayjs } from 'dayjs';

import { ChannelSummary } from './Channel';
import { VideoStats } from './VideoStats';

export type VideoType = 'live' | 'premiere' | 'video';

export class LiveStreamingDetails {
  public scheduledStartTime: Dayjs | null = null;
  public actualStartTime: Dayjs | null = null;
  public actualEndTime: Dayjs | null = null;
  public concurrentViewers: number | null = null;

  constructor(init?: Partial<LiveStreamingDetails>) {
    Object.assign(this, init);
  }

  public static fromJSON(data: { [key: string]: any }) {
    return new LiveStreamingDetails({
      scheduledStartTime: data.scheduledStartTime !== null ? dayjs(data.scheduledStartTime) : null,
      actualStartTime: data.actualStartTime !== null ? dayjs(data.actualStartTime) : null,
      actualEndTime: data.actualEndTime !== null ? dayjs(data.actualEndTime) : null,
      concurrentViewers: data.concurrentViewers,
    });
  }
}

export class Video {
  public id: string = '';
  public videoType: VideoType | null = null;
  public title: string = '';
  public description: string = '';
  public thumbnail: string = '';
  public thumbnailLarge: string = '';
  public duration: number | null = null;
  public channel: ChannelSummary = new ChannelSummary();
  public stats: VideoStats = new VideoStats();
  public publishedAt: Dayjs | null = null;
  public liveStreamingDetails: LiveStreamingDetails = new LiveStreamingDetails();
  public isScheduled: boolean = false;
  public isLive: boolean = false;
  public isPublished: boolean = false;
  public relatedVideos: RelatedVideos = new RelatedVideos();

  constructor(init?: Partial<Video>) {
    Object.assign(this, init);
  }

  public static fromJSON(data: { [key: string]: any }, relatedVideos: { [key: string]: any } = {}): Video {
    return new Video({
      id: data.id,
      videoType: data.videoType,
      title: data.title,
      description: data.description,
      thumbnail: data.thumbnail,
      thumbnailLarge: data.thumbnailLarge,
      duration: data.duration,
      channel: ChannelSummary.fromJSON(data.channel),
      stats: VideoStats.fromJSON(data.stats),
      publishedAt: data.publishedAt !== null ? dayjs(data.publishedAt) : null,
      liveStreamingDetails: LiveStreamingDetails.fromJSON(data.liveStreamingDetails),
      isScheduled: data.isScheduled,
      isLive: data.isLive,
      isPublished: data.isPublished,
      relatedVideos: RelatedVideos.fromRelatedVideos(data.relatedVideos, relatedVideos),
    });
  }

  get url() {
    return `https://youtu.be/${this.id}`;
  }

  /**
   * 動画の開始時間を返す
   */
  get startTime() {
    if (this.isPublished) {
      if (this.videoType === 'video') {
        return this.publishedAt;
      } else {
        return this.liveStreamingDetails.actualStartTime;
      }
    } else if (this.isScheduled) {
      return this.liveStreamingDetails.scheduledStartTime;
    } else if (this.isLive) {
      return this.liveStreamingDetails.actualStartTime;
    }

    return null;
  }

  hasRelatedVideos() {
    return (
      this.relatedVideos.concurrent.length > 0 ||
      this.relatedVideos.original.length > 0 ||
      this.relatedVideos.kirinuki.length > 0
    );
  }
}

export class VideoList {
  public items: Video[] = [];

  constructor(init?: Partial<VideoList>) {
    Object.assign(this, init);
  }

  public static fromItemsJSON(items: { [key: string]: any }[]) {
    return new VideoList({
      items: items.map((item: any) => Video.fromJSON(item)),
    });
  }

  // /**
  //  * 含まれるライブ配信動画のうちで最後のログイン時間（actualStartTime）を返す
  //  */
  // get lastestLoginTime() {
  //   let loginTime: Dayjs | null = null;
  //   for (const video of this.items) {
  //     if (video.videoType === 'live' && video.liveStreamingDetails.actualStartTime !== null) {
  //       if (loginTime === null) {
  //         loginTime = video.liveStreamingDetails.actualStartTime;
  //       } else if (video.liveStreamingDetails.actualStartTime.isAfter(loginTime)) {
  //         loginTime = video.liveStreamingDetails.actualStartTime;
  //       }
  //     }
  //   }
  //   return loginTime;
  // }

  // /**
  //  * 含まれるライブ配信動画のうちで最後のログアウト時間（actualEndTime）を返す
  //  */
  // get lastestLogoutTime() {
  //   let logoutTime: Dayjs | null = null;
  //   for (const video of this.items) {
  //     if (video.videoType === 'live' && video.liveStreamingDetails.actualEndTime !== null) {
  //       if (logoutTime === null) {
  //         logoutTime = video.liveStreamingDetails.actualEndTime;
  //       } else if (video.liveStreamingDetails.actualEndTime.isAfter(logoutTime)) {
  //         logoutTime = video.liveStreamingDetails.actualEndTime;
  //       }
  //     }
  //   }
  //   return logoutTime;
  // }
}

export class VideoTypes {
  public live: number = 0;
  public premiere: number = 0;
  public video: number = 0;

  constructor(init?: Partial<VideoTypes>) {
    Object.assign(this, init);
  }

  public static fromJSON(data: { [key: string]: any }) {
    return new VideoTypes({
      live: data.live,
      premiere: data.premiere,
      video: data.video,
    });
  }
}

export class RelatedVideos {
  concurrent: Video[] = [];
  original: Video[] = [];
  kirinuki: Video[] = [];

  constructor(init?: Partial<RelatedVideos>) {
    Object.assign(this, init);
  }

  public static fromRelatedVideos(data: { [key: string]: any }[], relatedVideos: { [key: string]: any }) {
    const concurrent = data
      .filter((data) => data.type === 'concurrent')
      .map((data) => relatedVideos[data.videoId])
      .filter((data) => data)
      .map((data) => Video.fromJSON(data));
    const original = data
      .filter((data) => data.type === 'original')
      .map((data) => relatedVideos[data.videoId])
      .filter((data) => data)
      .map((data) => Video.fromJSON(data));
    const kirinuki = data
      .filter((data) => data.type === 'kirinuki')
      .map((data) => relatedVideos[data.videoId])
      .filter((data) => data)
      .map((data) => Video.fromJSON(data));
    return new RelatedVideos({ concurrent, original, kirinuki });
  }
}
