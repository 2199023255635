import styled from 'styled-components';

const background = '#f1f1f1';
const boderColor = '#0f0f0f';
const borderWidth = 2;

export const Balloon = styled.div`
  position: relative;
  display: inline-block;
  margin: 1.5em 0 1.5em 15px;
  padding: 7px 10px;
  min-width: 120px;
  max-width: 100%;
  color: #555;
  font-size: 16px;
  background: ${background};
  border: solid ${borderWidth}px ${boderColor};
  border-radius: 8px;
  box-sizing: border-box;

  :before {
    content: '';
    position: absolute;
    top: 50%;
    left: -24px;
    margin-top: -12px;
    border: 12px solid transparent;
    border-right: 12px solid ${background};
    z-index: 2;
  }

  :after {
    content: '';
    position: absolute;
    top: 50%;
    left: -30px;
    margin-top: -14px;
    border: 14px solid transparent;
    border-right: 14px solid ${boderColor};
    z-index: 1;
  }
`;
