import React from 'react';
import styled from 'styled-components';

interface Props extends Omit<React.ComponentProps<'div'>, 'ref'> {
  ratio?: number; // 横の長さに対する縦の長さ
  transitionDuration?: React.CSSProperties['transitionDuration']; // 比率が変化する場合、アニメーションさせるか
}

const FixedRatioBox: React.FC<Props> = ({ className, children, ratio, transitionDuration = '0ms', ...props }) => {
  return (
    <Wrapper className={className} ratio={ratio} transitionDuration={transitionDuration} {...props}>
      <Content>{children}</Content>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ ratio?: number; transitionDuration: React.CSSProperties['transitionDuration'] }>`
  position: relative;
  width: 100%;

  :before {
    content: '';
    display: block;
    padding-top: ${({ ratio = 1 }) => `${(ratio * 100) / 1}%`};

    transition-duration: ${({ transitionDuration }) => transitionDuration};
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export default FixedRatioBox;
