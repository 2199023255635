import React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

export const WidgetManualPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>アツクラLIVE! Widget 説明書 | アツクラLIVE!</title>
      </Helmet>
      <Wrapper>
        <H1>ウィジェット説明書</H1>
        <Paragraph>
          アツクラの配信中メンバーをPCやスマートフォンのウィジェット表示するためモードです。
          <br />
          ウィジェットモードは、配信中メンバーのみの表示で固定です。
          <br />
          <br />
          <img src="/images/image/widget.jpg" alt="" style={{ width: '100%', maxWidth: 640 }} />
        </Paragraph>
        <H2>利用方法</H2>
        <Paragraph>
          PCやスマホのウィジェットアプリケーションのURLに以下を指定してください。
          <br />
          各パラメータ（key=value の部分）はウィジェットのサイズや形状に合わせて設定してください。
          <br />
          <br />
          <a
            target="_blank"
            href="https://atsukura.live/widget?maxrows=6&minrows=3&aspect=2.12&placeholder=text"
            rel="noreferrer"
            style={{ color: 'lightgray', textDecoration: 'none' }}
          >
            <i>https://atsukura.live/widget?maxrows=6&minrows=3&aspect=2.12&placeholder=text</i>
          </a>
        </Paragraph>
        <H2>パラメータ</H2>
        <Ul>
          <Li>
            <i>maxrows</i>
            <br />
            <br />
            １行に表示するアイコン数の最大値
            <br />
            デフォルト: 6
            <br />
            <br />
          </Li>
          <Li>
            <i>minrows</i>
            <br />
            <br />
            １行に表示するアイコン数の最小値。実際に配信中のメンバー数がこの数値より小さい場合もアイコンサイズが固定される
            <br />
            デフォルト: 3
            <br />
            <br />
          </Li>
          <Li>
            <i>aspect</i>
            <br />
            <br />
            横幅/縦幅の値
            <br />
            デフォルト: 2.12
            <br />
            <br />
          </Li>
          <Li>
            <i>placeholder</i>
            <br />
            <br />
            配信中メンバー数が0人の場合の表示。"text"は「現在ライブ配信中のメンバーはいません」、"image"はメンバー集合写真。
            <br />
            "text" or "image", デフォルト: "text"
            <br />
            <br />
          </Li>
        </Ul>
        <H1>ウィジェットアプリ</H1>
        Webページをウィジェットとして表示するためには別途アプリが必要です。iOSの場合、
        <a target="_blank" href="https://apps.apple.com/jp/app/widget-web/id1522169352" rel="noreferrer">
          Widget Web
        </a>
        を利用してアツクラLIVE!をウィジェット化できます。（上記スクショはこのアプリ使ってます。iOSは制限で更新間隔は最短で15分です。）
        <br />
        他のアプリやAndroidについては分かりません！
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  padding-bottom: 96px;
`;

const H1 = styled.h1`
  margin-top: 48px;
  margin-bottom: 12px;
`;

const H2 = styled.h2`
  margin-bottom: 12px;
`;

const Paragraph = styled.p`
  margin-bottom: 48px;
  padding: 12px;

  word-break: break-all;
`;

const Ul = styled.ul`
  margin-bottom: 48px;
`;
const Li = styled.li`
  list-style-type: disc;
  list-style-position: inside;
  text-indent: -20px;
  padding-left: 20px;
`;
