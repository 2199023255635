export class Stats {
  public videoCount: number = 0;
  public premiereVideoCount: number = 0;
  public liveVideoCount: number = 0;
  public viewCount: number = 0;
  public likeCount: number = 0;
  public favoriteCount: number = 0;
  public commentCount: number = 0;
  public videoDuration: number = 0;
  public premiereVideoDuration: number = 0;
  public liveVideoDuration: number = 0;

  constructor(init?: Partial<Stats>) {
    Object.assign(this, init);
  }

  public static fromJSON(data: { [key: string]: any }) {
    return new Stats({
      videoCount: data.videoCount,
      premiereVideoCount: data.premiereVideoCount,
      liveVideoCount: data.liveVideoCount,
      viewCount: data.viewCount,
      likeCount: data.likeCount,
      favoriteCount: data.favoriteCount,
      commentCount: data.commentCount,
      videoDuration: data.videoDuration,
      premiereVideoDuration: data.premiereVideoDuration,
      liveVideoDuration: data.liveVideoDuration,
    });
  }

  get totalVideoCount() {
    return this.videoCount + this.premiereVideoCount + this.liveVideoCount;
  }

  get totalDuration() {
    return this.videoDuration + this.premiereVideoDuration + this.liveVideoDuration;
  }
}
