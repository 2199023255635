import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Member } from '../../../models/Member';
import { ChannelType, PublishedVideoType } from '../../../types';
import { EmptyPlaceholder } from '../../atoms/EmptyPlacefolder';
import { SkinFace } from '../../atoms/SkinFace';
import { useLocalStorage } from '../../hooks/storage';
import { VideoCardListLoader } from '../../organisms/VideoCardListLoader';

type VideoPageTemplateProps = {
  className?: string;
  member?: Member;
  channelType: ChannelType;
  publishedVideoType: PublishedVideoType | 'kirinuki';
};

export const VideoPageTemplate: React.FC<VideoPageTemplateProps> = ({
  className,
  member,
  channelType,
  publishedVideoType,
}) => {
  const videoTypeOrVideoStatus =
    publishedVideoType === 'archive'
      ? 'published/live'
      : publishedVideoType === 'video'
      ? 'published/video'
      : publishedVideoType === 'kirinuki'
      ? 'published'
      : 'published';

  const titlePrefix = member !== undefined ? `${member.shortName}さんの` : '';
  let titleSuffix = '';
  if (channelType === 'kirinuki') {
    titleSuffix = '切り抜き動画';
  } else {
    titleSuffix =
      publishedVideoType === 'archive'
        ? 'ライブ配信アーカイブ'
        : publishedVideoType === 'video'
        ? '公開中の動画'
        : publishedVideoType === 'kirinuki'
        ? '切り抜き動画'
        : 'ライブ配信アーカイブ・公開中の動画';
  }

  const [displayDate, setDisplayDate] = useLocalStorage('DISPLAY_DATE', false, false);

  const title = titlePrefix + titleSuffix;

  const memberIdOrAll =
    member === undefined ? 'all' : publishedVideoType === 'kirinuki' ? `members/${member.id}` : member.id;

  return (
    <>
      <Helmet>
        <title>{title} | アツクラLIVE!</title>
      </Helmet>
      <Wrapper className={className}>
        <Section>
          <VideoCardListWrapper>
            <SectionTitleWrapper>
              <SectionTitle>
                {member && <StyledSkinFace nameTag={member.id} size={20} />}
                {title}
              </SectionTitle>
              <CheckboxWrapper>
                <Checkbox
                  id="display-date"
                  type="checkbox"
                  checked={displayDate}
                  onChange={(event) => setDisplayDate(event.target.checked)}
                />
                <CheckboxLabel htmlFor="display-date">日付でまとめる</CheckboxLabel>
              </CheckboxWrapper>
            </SectionTitleWrapper>
            {channelType === 'kirinuki' && (
              <Description>
                切り抜きチャンネルの動画からアツクラ配信の切り抜き動画をのみを表示しています。取り扱っている切り抜きチャンネルの一覧は
                <StyledLink to="/kirinuki_channels">こちら</StyledLink>。
              </Description>
            )}
            <VideoCardListLoader
              key={publishedVideoType}
              channelType={channelType}
              memberIdOrAllOrForMembers={memberIdOrAll}
              videoTypeOrVideoStatus={videoTypeOrVideoStatus}
              emptyPlaceholder={<EmptyPlaceholder>{title}はありません</EmptyPlaceholder>}
              displayDate={displayDate}
            />
          </VideoCardListWrapper>
        </Section>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  margin-top: 24px;
`;
const Section = styled.div``;

const SectionTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
const SectionTitle = styled.div`
  font-size: 20px;
`;
const Description = styled.div`
  font-size: 14px;
  color: #999;
  margin: 12px 0;
`;
const StyledLink = styled(Link)`
  text-decoration: underline;
  color: #999;
`;
const VideoCardListWrapper = styled.div``;
const StyledSkinFace = styled(SkinFace)`
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  margin-top: -2px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-left: 12px;
`;

const Checkbox = styled.input.attrs(() => ({ type: 'checkbox' }))`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const CheckboxLabel = styled.label`
  margin-left: 8px;
  user-select: none;
  font-size: 16px;
  cursor: pointer;
`;
