import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

export type SwitchImageProps = {
  className?: string;
  src: string;
  transitionDuration?: number;
  transitionDelay?: number;
  imgStyle?: React.CSSProperties;
};

export const SwitchImage: React.FC<SwitchImageProps> = ({
  className,
  src,
  transitionDuration = 300,
  transitionDelay = 0,
  imgStyle,
}) => {
  const [src1, setSrc1] = useState<string>(src);
  const [src2, setSrc2] = useState<string>(src);
  const [side, setSide] = useState<1 | 2>(1);

  useEffect(() => {
    if (side === 1) {
      if (src !== src1) {
        setSrc2(src);
        setSide(2);
      }
    } else {
      if (src !== src2) {
        setSrc1(src);
        setSide(1);
      }
    }
  }, [side, src1, setSrc1, src2, setSrc2, src]);

  return (
    <Wrapper className={className}>
      <CSSTransition in={side === 1} timeout={0}>
        <Image1
          src={src1}
          active={side === 1}
          transitionDuration={transitionDuration}
          transitionDelay={transitionDelay}
          style={imgStyle}
        />
      </CSSTransition>
      <CSSTransition in={side === 2} timeout={0}>
        <Image2
          src={src2}
          active={side === 2}
          transitionDuration={transitionDuration}
          transitionDelay={transitionDelay}
          style={imgStyle}
        />
      </CSSTransition>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  user-select: none;
`;

const BaseImage = styled.img<{
  isInitial?: boolean;
  active: boolean;
  transitionDuration: number;
  transitionDelay: number;
}>`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;

  transition-duration: ${({ isInitial, transitionDuration }) => (isInitial ? '0ms' : `${transitionDuration}ms`)};
  transition-timing-function: ease-in-out;
  transition-property: opacity;
  transition-delay: ${({ active, transitionDelay }) => (active ? '0ms' : `${transitionDelay}ms`)};

  &.enter {
    opacity: 0;
  }
  &.enter-active,
  &.enter-done {
    opacity: 1;
  }

  &.exit {
    opacity: 1;
  }
  &.exit-active,
  &.exit-done {
    opacity: 0;
  }
`;

const Image1 = styled(BaseImage)``;
const Image2 = styled(BaseImage)`
  position: absolute;
  top: 0;
  opacity: 0;
`;
