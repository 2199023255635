import { useEffect } from 'react';

const useScrollLock = (enabled: boolean = true) => {
  useEffect(() => {
    if (window) {
      document.body.style.overflow = enabled ? 'hidden' : '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [enabled]);
};

export default useScrollLock;
